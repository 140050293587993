import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../services/generalservice.service';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
declare var $

@Component({
  selector: 'app-technician',
  templateUrl: './technician.component.html',
  styleUrls: ['./technician.component.scss']
})
export class TechnicianComponent implements OnInit {
  serviceengineer: any;
  cols: any;
  Techinfo: any;
    technicianid: any;
  TechnicianId: any;
  HomeURL: any;
  //http: any;
    image: any;
  constructor(public generalService: GeneralserviceService,public http: HttpClient) {
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeURL = data.Webservice;
    })

    this.Getserviceengineer();


    this.cols = [

    //  { field: 'TechnicianId', header: '#', width: '80px' },
      { field: 'Technicianname', header: 'Technician Name', width: '170px' },
      { field: 'PhoneNumber', header: 'Phone Number', width: '150px' },
      { field: 'Email', header: 'Email', width: '230px' },
      //{ field: 'Token', header: 'Token', width: '150px' },
      { field: 'Address', header: 'Address', width: '200px' },
      { field: 'Coins', header: 'Coins', width: '130px' },
      //{ field: 'Password', header: 'Password', width: '130px' },
      //{ field: 'Password', header: 'Password', width: '130px' },
      //{ field: 'Password', header: 'Password', width: '130px' },



    ];
  }
  ngOnInit() {

      }
  /*Get Technician */
  Getserviceengineer() {


    debugger
    var url = "/api/Values/Get_TechnicianGrid";
   


      this.generalService.GetData(url).then((data: any) => {
        debugger
        this.serviceengineer = data;

       
      }, err => {
        this.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')

      });
    }



  /*end code*/

  Viewtechinfo(rowData) {
    debugger
    //this.spinner.show();

    technicianid: this.technicianid,
      this.technicianid = rowData.TechnicianId

    var FormDat = new FormData();
    FormDat.append("technicianid", this.technicianid);
    var url = "/api/Values/Get_Technician_kyc";
    this.generalService.PostData(url, FormDat).then((data: any) => {
      debugger
      this.Techinfo = data;
      //this.image = this.HomeURL + data;

      debugger
      if (data.length != 0) {
        debugger
        this.Techinfo = data;
        //this.spinner.hide();
        $('#NmyModal').modal('show');
      }

      else {
        //$('#NmyModal').modal('hide');

        //this.spinner.hide();
        this.generalService.ShowAlert('INFO', 'No Data Found', 'info');
      }
    }, err => {
      //this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }


  ShowAlert(title: any, text: any, type: any) {
      Swal.fire({
        title: title,
        html: text,
        confirmButtonText: 'Ok'
      })
    }
}
