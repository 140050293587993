import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest, } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
declare var $

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html', 
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  loginDet: any;
  cols: any;
  HomeUrl: any;
  Notifications: any;
  msg: any;
  spin: boolean = false;
  Name: any;
  Form: FormGroup;
  deptList: any;
  dataResult: any;
  arr: any;
  Tests: any = [];
  //UserName: any;
  Users: any;
  sendername: string;
  User_Id: any;
  dataResult1: any;
  SkillRoleID: any;
  dataResult2: any;
  User: any;
  dataResult123: any;
  dataResult4: any;
  dataResult3: any;
  UserID: any;
  UserName: any;
  UserName1: any;
  UserName2: any;
  serviceengineer: any;
  customers: any;

  constructor(public generalService: GeneralserviceService, public fb: FormBuilder, public router: Router, public http: HttpClient) {
    this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));

    this.Form = fb.group({
      msg: ['', Validators.required],

    });
    this.cols = [
      { field: 'CreatedDate', header: 'Sent Date', width: '60px' },
      { field: 'Notification', header: 'Notifications', width: '200px' },
      // { field: 'UserName', header: 'User', width: '200px' },
    ]
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    });
  }

  ngOnInit(): void {
    this.GetNotifications();
    this.Getserviceengineer();
    this.Getcustomers();
  //  this.GetConsioner();
    //   this.GetAllUsers();

  }
  GetNotifications() {
    debugger
    var url = "api/Values/HQServiz_Get_AdminNotifications";
    this.generalService.GetData(url).then((data: any) => {
      debugger
      this.Notifications = data;

    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')

    });

  }

  /* Get_Technicians */
  Getserviceengineer() {

    debugger
    var url = "/api/Values/Get_TechnicianGrid";

    this.generalService.GetData(url).then((data: any) => {
      debugger

      this.serviceengineer = data;


    }, err => {
      this.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')

    });
  }


  /*Get customers */
  Getcustomers() {
    debugger
    var url = "/api/Values/HQ_Customer_Grid";


    this.generalService.GetData(url).then((data: any) => {
      debugger
      this.customers = data;


    }, err => {
      this.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')

    });
  }

  /*end code*/

  //GetConsioner() {
  //  debugger
  //  this.arr = []
  //  this.arr.push({
  //    UserID: this.loginDet.UserID,
  //    TokenID: this.loginDet.TokenID,
  //    RoleID: 1
  //  })

  //  var UploadFile = new FormData();
  //  UploadFile.append("Param", JSON.stringify(this.arr));
  //  // UploadFile.append('Flag', '4');
  //  var url = "api/BuddyOnWheel/Get_Admin_Users";
  //  this.generalService.PostData(url, UploadFile).then(data => {
  //    this.dataResult4 = data;

  //  },
  //    err => {
  //      this.generalService.ShowAlert('ERROR', 'Something went wrong,please try agian later', 'error');
  //    });
  //}


  openAddForm() {

    debugger
    $('#modal').modal('show');
    this.Clear();
  }
  Clear() {

    this.msg = null
    this.Name = null

  }



  SendNotification(UserName, UserName1, msg) {
    debugger
    if (UserName != null && msg != null) {
      for (var i = 0; i < UserName.length; i++) {
        this.Tests.push({

          TechnicianId: UserName[i].TechnicianId,
          Token: UserName[i].Token,
          Notification: this.msg,
          CreatedBy: 1
        });
      }
    }

    else if (UserName1 != null && msg != null) {
      for (var i = 0; i < UserName1.length; i++) {
        this.Tests.push({

          CustomerId: UserName1[i].CustomerId,
          DeviceToken: UserName1[i].DeviceToken,
          Notification: this.msg,
          CreatedBy: 1
        });
      }
    }

    

    else {
      this.generalService.ShowAlert('Error', 'please enter Message Here.', 'error');

    }
    var FormDat = new FormData();
    FormDat.append("Notification", JSON.stringify(this.Tests));
    var url = "api/Values/HQSeviz_Add_AdminNotifications";
    this.generalService.PostData(url, FormDat).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        this.generalService.ShowAlert('SUCCESS', 'Notification added successfully.', 'SUCCESS');
        this.sendername = 'HQ Service';
        if (UserName != null && msg != null) {
          for (var i = 0; i < UserName.length; i++) {

            this.UserID = UserName[i].Token

            var UploadFile = new FormData();
            UploadFile.append("deviceToken", this.UserID);
            UploadFile.append("message", this.msg);
            UploadFile.append("senderName", this.sendername);
            var url = "api/Values/sendNotification";
            this.generalService.PostData(url, UploadFile).then(data => {


              this.generalService.ShowAlert('SUCCESS', 'Notification added successfully.', 'SUCCESS');
              location.reload();
            })
          }
        }
        else if (UserName1 != null && msg != null) {
          for (var i = 0; i < UserName1.length; i++) {

            this.UserID = UserName1[i].DeviceToken
            var UploadFile = new FormData();
            UploadFile.append("deviceToken", this.UserID);
            UploadFile.append("message", this.msg);
            UploadFile.append("senderName", this.sendername);
            var url = "api/Values/SendFCMNotificationCustomer";
            this.generalService.PostData(url, UploadFile).then(data => {

              this.generalService.ShowAlert('SUCCESS', 'Notification added successfully.', 'SUCCESS');
              location.reload();
            })
          }


        }
        //else if (msg != null) {
        //  for (var i = 0; i < UserName2.length; i++) {


        //    this.UserID = UserName2[i].DeviceToken


        //    var UploadFile = new FormData();
        //    UploadFile.append("Devicetoken", this.UserID);
        //    UploadFile.append("MSG", this.msg);
        //    UploadFile.append("SenderName", this.sendername);
        //    var url = "api/Values/sendNotification";
        //    this.generalService.PostData(url, UploadFile).then(data => {

        //      this.generalService.ShowAlert('SUCCESS', 'Notification added successfully.', 'SUCCESS');
        //      location.reload();
        //    })
        //  }


        //}
        else {
          this.generalService.ShowAlert('Error', 'please enter Message Here.', 'error');

        }
      //  this.GetNotifications();

      }

    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error');

    })
  }


  ShowAlert(title: any, text: any, type: any) {
    Swal.fire({
      title: title,
      html: text,
      //icon: type,
      confirmButtonText: 'Ok'
    })
  }



}


