<div class="row   ml-n5 mt-n3">
  <div class="col">
    <div class="tab-content tab-content">
      <div class="tab-pane fade show active" id="p1">

        <p-table #dt [columns]="cols" [value]="dataResult" [scrollable]="true" [responsive]="true" [rows]="15" [paginator]="true" scrollHeight="320px">
          <ng-template pTemplate="caption">
            <div class="row">
              <div class="col-lg-1 mt-5" style="text-align: left;margin-bottom: 0;">
                <!--<i (click)="openAddForm()" class="pi pi-plus addIcon" pTooltip="Add Product" tooltipPosition="top" style="font-size:20px;"></i>-->
                <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
                   aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>

              </div>

              <div class="form-group col-lg-3 col-md-2 col-sm-6 ml-n5 mr-n3 ">
                <label for="input-13" class="star@t">Start Date</label>
                <input type="date" class="form-control d@ate date_0" [(ngModel)]="fromDate" name="startdate" id="input-12" [max]="maxdate" (input)="Checkdate(fromDate)">
              </div>
              <div class="form-group col-lg-3 col-md-2 col-sm-6 ">
                <label for="input-13" class="e@nd">End Date</label>
                <input type="date" class="form-control dat@e_1 date_1" [(ngModel)]="toDate" name="enddate" id="input-13" [min]="mindate">
              </div>
              <div class="form-group col-lg-1 col-md-1 col-sm-6 mt-1 Gener@ate">
                <br />
                <button type="submit" style="width:3rem !important;" class="btn btn-primary gen" (click)="getprojects(fromDate,toDate)">
                  Go
                </button>
              </div>

              <div class="col-lg-4 mt-5 ml-5" style="text-align: right;margin-bottom: 0;">
                {{dt.filteredValue?dt.filteredValue.length:dt.totalRecords}} of {{dt.totalRecords}}
                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                <input type="text" class="global_search" pInputText size="50" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:200px;">
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <!--<col width="100px" />-->

              <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
            </colgroup>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <!--<th>View</th>-->

              <th  *ngFor="let col of columns" [pSortableColumn]="col.field">
                <span>{{col.header}}</span>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <!--<td class="col-btn">
                <i class="fa fa-eye" pTooltip="Viewtechinfo" style="color:green; position:center" (click)="Viewtechinfo(rowData)"></i> <span class="icon-name"></span>
              </td>-->


              <td *ngFor="let col of columns" [ngSwitch]="col.field"  [pTooltip]="rowData[col.field]" tooltipPosition="top">
                <span class="a_g99 pl-1" *ngSwitchDefault>{{rowData[col.field]}}</span>
                <span *ngSwitchCase="'AcceptedDate'"[pTooltip]="rowData[col.field]" tooltipPosition="top">{{rowData[col.field]| date:'dd-MM-YYYY,hh:mm:a'}}</span>
                <span *ngSwitchCase="'BookingDate'"[pTooltip]="rowData[col.field]" tooltipPosition="top">{{rowData[col.field]| date:'dd-MM-YYYY,hh:mm:a'}}</span>
              </td>

            </tr>
          </ng-template>
        </p-table>

      </div>
    </div>
  </div>
</div>



<style>
  td.border-right {
    border-right: 1px solid black;
  }

  tr:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }

  .a_g99 {
    width: 100px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .mt-5, .my-5 {
    margin-top: 2rem !important;
  }

  .griddd {
    margin-bottom: -20px;
    margin-top: -8px;
  }
</style>


