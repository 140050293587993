<div class="row ml-n5 mt-n3">
  <div class="col">
    <div>
      <div id="p1">
        <p-table #dt [columns]="cols" [value]="Notifications" [scrollable]="true" [responsive]="true" [rows]="15"
                 [paginator]="true">
          <!--scrollHeight="400px"-->
          <!--<p-table #dt [columns]="cols" [value]="Notifications" [responsive]="true" [rows]="18" [paginator]="true">-->
          <ng-template pTemplate="caption">
            <div class="row">
              <div class="col-lg-4" style="text-align: left;margin-bottom: 0;">
                <i (click)=" openAddForm()" class="fa fa-plus" data-toggle="modal" data-target="#exampleModal"
                   aria-label="" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;">&nbsp;&nbsp;Add New Notifications</i>
              </div>

            </div>
            <div class="row"> 

              <div class="col-lg-6" style="text-align: right;margin-bottom: 0; margin-top: -18px;
    margin-left: 502px;">
                {{dt.filteredValue?dt.filteredValue.length:dt.totalRecords}} of {{dt.totalRecords}}
                <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                <input type="text" class="global_search search" pInputText size="50" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto; ">
              </div>

            </div>
          </ng-template>
          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>

              <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">

            </colgroup>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>

              <th style="width:100px;" *ngFor="let col of columns" [pSortableColumn]="col.field">
                <span>{{col.header}}</span>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>


              <td *ngFor="let col of columns" [ngSwitch]="col.field">
                <span class="a_g99" style="margin-left:16px;word-break:break-word" *ngSwitchDefault>{{rowData[col.field]}}</span>
                <span *ngSwitchCase="'DOB'">{{rowData[col.field] | date:'dd-MMM-yyyy'}}</span>
                <span *ngSwitchCase="'CreatedDate'" style="margin-left:15px;">{{rowData[col.field] | date:'dd-MMM-yyyy'}}</span>
                <!--<span *ngSwitchCase="'Time'">{{rowData[col.field] date :'short'}}</span>-->
              </td>


            </tr>
          </ng-template>

        </p-table>

      </div>
    </div>
  </div>
</div>


<div class="modal" id="exampleModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width:150%">
      <div class="modal-header state modal-primary">
        <h5 class="modal-title" id="exampleModalLabel" style="height:100%">Send Notification To All Customers/Technicians </h5>
        <button type="button" style="font-size:21px;" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="contact-form row">

          <div class="col-lg-12">

            <label for="UserName" class="col-form-label" style="margin-left:7%;margin-top:10px;"> Select Technicians<span style="color:red"></span></label>
            <div style="margin-top:-5%;margin-bottom:2%;margin-left:28%;width:110%;">
              <p-multiSelect [options]="serviceengineer" [(ngModel)]="UserName" defaultLabel="Select a Technicianname" optionLabel="Technicianname" display="chip" [ngModelOptions]="{standalone: true}"></p-multiSelect>

            </div>
            <label for="UserName1" class="col-form-label" style="margin-left:7%;"> Select Customers<span style="color:red"></span></label>
            <div style="margin-top:-5%;margin-bottom:2%;margin-left:28%;width:110%;">
              <p-multiSelect [options]="customers" [(ngModel)]="UserName1" defaultLabel="Select a Customer" optionLabel="FirstName" display="chip" [ngModelOptions]="{standalone: true}"></p-multiSelect>


            </div>
            <!--<label for="UserName" class="col-form-label" style="margin-left:7%;"> Select Consignor<span style="color:red"></span></label>
            <div style="margin-top:-5%;margin-bottom:2%;margin-left:28%;width:110%;">
              <p-multiSelect [options]="dataResult4" [(ngModel)]="UserName" defaultLabel="Select a User" optionLabel="UserName" display="chip" [ngModelOptions]="{standalone: true}"></p-multiSelect>


            </div>-->
          </div>

          <div class="row">
            <div class="col col-sm-1"></div>
            <div class="col col-sm-10">
              <textarea [(ngModel)]="msg" [ngModelOptions]="{standalone: true}" rows="10" type="text" class="form-control" placeholder="Enter Notification Message*" style="width:250%;"></textarea>

            </div>
            <div class="col col-sm-1"></div>
          </div>


          <div class="col col-sm-12 text-center" style="margin-left: 104px;">
            <button type="button" class="button_2" data-dismiss="modal" style="margin-right: 15px; margin-top: 10px;">
              Close
            </button>
            <button type="submit" (click)="SendNotification(UserName,UserName1,msg)" data-dismiss="modal" class="button_1">Send Notification</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<style>
  /*.a_g99 {
        width: 90%;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
    }*/
</style>


