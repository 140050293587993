import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../services/generalservice.service';
import Swal from 'sweetalert2';
import { ToastrService, GlobalConfig } from 'ngx-toastr';

//import { SpinnerOverlayService } from '../../services/spinner-overlay.service';
declare var $



@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss'],
 
})
export class BookingsComponent implements OnInit {
  serviceticket: any;
  cols: any;
  serviceinfo: any;
  ServiceTickedId: any;
 // hide: boolean = true;
  overlayRef: any;
   arr: any;
  ticketsList: any = [];
  ticketsListALL: any = [];
  ticketsListOpen: any = [];
  ticketsListInPro: any = [];
  ticketsListClosed: any = [];
  ticketsListReopen: any = [];
  ticketTypeList: any = [];
  TicketStatus: any;
  TicketType: any;
    Name: string;
    serviceticket1: any;
  spareDetails: any;
  TotalPrice: any;
    ticketsCancel: any;
  constructor(public generalService: GeneralserviceService,

    //public spinner: SpinnerOverlayService,
    public toastrService: ToastrService) {

    this.getserviceticket();
    
    
    this.cols = [


     // { field: 'ServiceTickedId', header: 'ServiceTickedId', width: '150px' },
      { field: 'CreatedDate', header: 'Date', width: '150px' },
      { field: 'TicketNumber', header: 'Booking Number', width: '170px' },
      { field: 'CustomerName', header: 'Customer Name', width: '170px' },
      { field: 'Mobile', header: 'Mobile', width: '120px' },
      { field: 'Technicianname', header: 'Technician Name', width: '170px' },
     // { field: 'ServiceInformation', header: 'ServiceInformation', width: '190px' },
      { field: 'PhoneNumber', header: 'Phone Number', width: '150px' },
      { field: 'TotalPrice', header: 'Total Price', width: '120px' },
      { field: 'FinalPrice', header: 'Rate Card Price', width: '150px' },
     // { field: 'TicketStatus', header: 'TicketStatus', width: '150px' },
      { field: 'StartTime', header: 'Start Time', width: '150px' },
      { field: 'EndTime', header: 'End Time', width: '150px' },
      { field: 'TimeSlot', header: 'Slot Timings', width: '139px' },
      { field: 'RequestedDate', header: 'Slot Date', width: '150px' },
      { field: 'CancellationReason', header: 'CancellationReason', width: '150px' },
     // { field: 'LastAssignTo', header: 'LastAssignTo', width: '150px' },
      { field: 'Coins', header: 'Coins', width: '100px' },
      { field: 'TotalAmount', header: 'Final Amount', width: '150px' },
      
    //  { field: 'TicketStatus', header: 'TicketStatus', width: '150px' },
    ];
  }

 
  ngOnInit() {
  }
  
  /*Get serviceticket */
  getserviceticket() {
    debugger
    var url = "/api/Values/Get_Bookings_Admin";


    this.generalService.GetData(url).then((data: any) => {
      debugger
      this.serviceticket1 = data;
      this.serviceticket = this.serviceticket1.filter(x => x.TicketStatus == 1);;
      if (data) {
       
        this.ticketsListALL = data;
        this.ticketsList = data;
       
        this.ticketsListOpen = this.ticketsList.filter(x => x.TicketStatus == 1);
        this.ticketsListInPro = this.ticketsList.filter(x => x.TicketStatus == 2);
        this.ticketsListClosed = this.ticketsList.filter(x => x.TicketStatus == 3);
        this.ticketsCancel = this.ticketsList.filter(x => x.TicketStatus == 4);
      }
    
    }, err => {
      this.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')

    });

  }
  getTicketsByStatus(TicketStatus: any) {
    debugger
    if (TicketStatus == '1') {
      this.serviceticket = this.ticketsListALL.filter(x => x.TicketStatus == TicketStatus);
      this.Name = 'InProgress';
    }
    else if (TicketStatus == '2') {
      this.serviceticket = this.ticketsListALL.filter(x => x.TicketStatus == TicketStatus);
      this.Name = 'Assigned';
    }
    else if (TicketStatus == '3') {
      this.serviceticket = this.ticketsListALL.filter(x => x.TicketStatus == TicketStatus);
      this.Name = 'Completed';
    }
    else if (TicketStatus == '4') {
      this.serviceticket = this.ticketsListALL.filter(x => x.TicketStatus == TicketStatus);
      this.Name = 'CancellationReasonId';
    }
    else {
      this.serviceticket = this.ticketsListALL;
      this.Name = '';

    }
  }

  //getserviceinfo() {
  //  debugger
  //  var url = "/api/Values/Get_BookinginfoSubquery";


  //  this.generalService.GetData(url).then((data: any) => {
  //    debugger
  //    this.serviceinfo = data;


  //  }, err => {
  //    this.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')

  //  });

  //}
/*end code*/

  /*getting customer sale stock products   */
  Viewserviceinfo(rowData) {
    debugger
    //this.spinner.show();

      ServiceTickedId: this.ServiceTickedId,
      this.ServiceTickedId = rowData.ServiceTickedId
    
    var FormDat = new FormData();
    FormDat.append("serviceTicketId", this.ServiceTickedId);
    var url = "/api/Values/Get_BookinginfoSubquery";
      this.generalService.PostData(url, FormDat).then((data: any) => {
      debugger
        this.serviceinfo = data.Bookings;
        this.spareDetails = data.spareDetails;
        this.GetPrice();
      debugger
      if (data.length != 0) {
        debugger
        this.serviceinfo = data.Bookings;
        this.spareDetails = data.spareDetails;
        this.GetPrice();
       //this.spinner.hide();
        $('#NmyModal').modal('show');
      }

      else {
        //$('#NmyModal').modal('hide');

        //this.spinner.hide();
        this.generalService.ShowAlert('INFO', 'No Data Found', 'info');
      }
    }, err => {
      //this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }
  GetPrice() {
    debugger
    this.TotalPrice = 0;
    for (var i = 0; i < this.spareDetails.length; i++) {
      this.TotalPrice = this.TotalPrice + (this.spareDetails[i].Price * this.spareDetails[i].Quantity);
    }
    for (var k = 0; k < this.serviceinfo.length; k++) {
      this.TotalPrice = this.TotalPrice + (this.serviceinfo[k].Price * this.serviceinfo[k].Quantity);
    }
  }

  ShowAlert(title: any, text: any, type: any) {
    Swal.fire({
      title: title,
      html: text,
      //icon: type,
      confirmButtonText: 'Ok'
    })
  }
 
 

}




