  <div class="card-body pt-0 widget-body">
    <div *ngIf="gridview">
      <div class="card" style="padding:20px">

        
        <fieldset>
          <legend>  <h2 style="text-align:center">{{ButtonText}} Products</h2><br /></legend>
          <div class="row">
            <div class="col-lg-2 mt-n5">
              <button type="button" (click)="Back()" class="btn btn-danger btn-border-radius waves-effect"> <i class="material-icons"></i> <span class="icon-name">Back</span></button>

            </div>
            <div class="col-lg-8 " style="text-align:center">

            </div>
          </div>

          <div class="row">
            <div class="col-lg-4">
              <div>
                <label ffor="email-b-f">Category<span style="color:red">*</span></label>
                <select *ngIf="Flag==1" style="height: 33px !important;" [(ngModel)]="CatId" (click)="FilterSubCategories(CatId)" class="form-control show-tick">
                  <option disabled value="0">Select Category</option>
                  <!--<option  disabled value="0">Select Category</option>-->
                  <option *ngFor="let cat of Category" [value]="cat.ServiceCategoryId">{{cat.ServiceCategory}}</option>
                </select>
                <select *ngIf="Flag==2" style="height: 33px !important;" [(ngModel)]="CatId" class="form-control show-tick">
                  <option disabled value="0">Select Category</option>
                  <option *ngFor="let cat of Category" [value]="cat.ServiceCategoryId">{{cat.ServiceCategory}}</option>
                </select>


              </div>
            </div>
            <div class="col-lg-4">

              <div>
                <label for="email-b-f">Sub Category<span style="color:red">*</span></label>
                <select *ngIf="Flag==1" style="height: 33px !important;" [(ngModel)]="SubCatId" class="form-control show-tick">
                  <option value="0">Select SubCategory</option>

                  <option *ngFor="let cat of Subcategory" [value]="cat.TypeServicesCategoryId">{{cat.TypeServiceCategory}}</option>
                </select>
                <select *ngIf="Flag==2" style="height: 33px !important;" [(ngModel)]="SubCatId" class="form-control show-tick">
                  <option disabled value="0">Select Category</option>
                  <option *ngFor="let cat of Subcategory" [value]="cat.TypeServicesCategoryId">{{cat.TypeServiceCategory}}</option>
                </select>
              </div>

            </div>
            <div class="col-lg-4">

              <div>
                <label for="email-b-f">Product <span style="color:red">*</span></label>
                <input class="form-control" [(ngModel)]="TypeServiceDetail" type="email">
              </div>
            </div>


            <div>


            </div>

          </div>


          <br />
          <div class="row">
            <div class="col-lg-4">

              <label for="email-b-f">Price</label>
              <input class="form-control" type="email" [(ngModel)]="Price">

            </div>
            <div class="col-lg-8">

              <label for="email-b-f"> Description<span style="color:red">*</span></label>
              <textarea class="form-control" type="email" [(ngModel)]="Description"></textarea>

            </div>
          </div>
          <br />

          <br />
          <div style="font-size: 16px;font-weight: bold;letter-spacing: 1px;">Category Image</div>

          <div class="row">
            <div *ngIf="Flag==1||Flag==2" class="col-sm-6">
              <div class="images-container">
                <div class="m-image-wrapper">
                  <img [attr.src]="imagefile" *ngIf="image" style="cursor:pointer;">
                </div>
              </div>
              <br />
              <input type="file" multiple (change)="detectFiles(input)" [(ngModel)]="ProductImage" accept="application/pdf,image/*" #input class="m-img-upload-btn" />

              <button class="btn btn-success btn-block" type="button" style="width:30% !important;">
                <i class="fa fa-upload"></i> Upload Images
              </button>


            </div>
          </div>

          <br />
          <div style="text-align:right">
            <button type="button" style="margin-top:0px;" (click)="Back()" class="btn btn-danger m-t-15 waves-effect">CANCEL</button>&nbsp;
            <button type="submit" (click)="AddProduct(ServiceCategory, TypeServiceCategory, Description, TypeServiceDetail, Price)" class="btn btn-primary m-t-15 waves-effect">{{ButtonText}}</button>

          </div>
        </fieldset>
      </div>

    </div>

  </div>
  <div class="row  ml-n5 mt-n3">
    <div class="col">
      <div class="tab-content tab-content" *ngIf="!gridview">
        <div class="tab-pane fade show active" id="p1">
          <p-table #dt [columns]="cols" [value]="Products" [responsive]="true" [scrollable]="true" [rows]="50" [paginator]="true" scrollHeight="220px">
            <ng-template pTemplate="caption">
              <div class="row">
                <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
                  <i (click)=" openAddForm()" class="fa fa-plus addIcon" pTooltip="Add Product" tooltipPosition="top" style="font-size:20px;"></i>
                  <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
                     aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>

                </div>
                <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
                  {{dt.filteredValue?dt.filteredValue.length:dt.totalRecords}} of {{dt.totalRecords}}
                  <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                  <input type="text" class="global_search" pInputText size="50" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>

                <col style="width:100px">

                <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">

              </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>


                <th>Action</th>

                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  <span>{{col.header}}</span>
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>

              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td class="col-btn pl-1">
                  <i (click)="confirmDelete(rowData)" class="fa fa-trash" style="float:initial;color: red;cursor:pointer;font-size: 1.5em"></i>
                  &nbsp; <i class="fa fa-pencil-square" pTooltip="Edit" style="color:green;    font-size: 17px;" (click)="Edit(rowData)"></i>
                  &nbsp;  <i class="fa fa-eye" pTooltip="ViewImage" style="color: green; font-size: 16px; position: center" (click)="ViewImage(rowData)"></i>
                </td>



                <td class="pl-1"*ngFor="let col of columns" [ngSwitch]="col.field">
                  <span class="a_g99 pl-1" *ngSwitchDefault>{{rowData[col.field]}}</span>
                  <div style="text-align:right;">
                    <span class="pl-1" *ngSwitchCase="'offerprice'"><span>{{rowData[col.field]}}</span></span>
                    <span class="pl-1" *ngSwitchCase="'MRp'"><span>{{rowData[col.field]}}</span></span>
                    <span class="pl-1" *ngSwitchCase="'CGST'"><span>{{rowData[col.field]}}</span></span>
                    <span class="pl-1"  *ngSwitchCase="'SGST'"><span>{{rowData[col.field]}}</span></span>
                  </div>
                  <!--<div style="text-align:right;">
                    <span *ngSwitchCase="'Mrp'"><span>{{rowData[col.field]}}</span></span>
                  </div>-->
                  <!--<div *ngSwitchCase="'Image'">
                    <u *ngIf="rowData[col.field]!=''&&rowData[col.field]!=null" style="color:blue;cursor:pointer" (click)="ShowImage(rowData.Image)">View</u>
                    <span *ngIf="rowData[col.field]==''||rowData[col.field]==null" style="width:40%;">No Image</span>
                  </div>-->
                  <!--<span *ngSwitchCase="'CreatedDate'">{{rowData[col.field]|date:'medium'}}</span>-->
                </td>

              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
 




<div class="modal" id="NmyModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header state modal-primary">
        <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">{{TypeServiceDetailsId}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"style="color:white;">×</span></button>
      </div>
      <div class="modal-body">
        <div class="row" style="text-align:left;    padding: 1rem;
    padding-bottom: 0 !important;">
          <div class="row" *ngFor="let item of Image; let i=index" style="width: 10%;">

            <div class="col-lg-12">

              <div style="font-family: poppins;color: #000;font-size: 15px;padding-top: 1rem;letter-spacing: 0.5px; "> <img src="{{HomeURL+item.Image}}" style="width:30rem;" /></div>

            </div>

          </div>

          <br />

          <br />
          <div class="modal-footer" style="float:right;width: 100%;">
            <button style="  background-color: #123654;color: #fff;font-family: poppins; font-size: 18px; letter-spacing: 1px;
    margin-top: 2rem;background-color:orangered;" id="closemodal" type="button" class="btn btn-default" data-dismiss="modal">
              Close
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<style>



  .modal-dialog1 {
    max-width: 31% !important;
    margin: 1.75rem auto;
  }


  .app.green .btn-success {
    color: #fff;
    background-color: #4BAE4F;
    border-color: #4BAE4F;
    width: 39% !important;
    letter-spacing: 1px !important;
    margin-top: 1% !important;
  }

  .ml-n5, .mx-n5 {
    margin-left: -2.6rem !important;
  }

  .mt-n3, .my-n3 {
    margin-top: -1.8rem !important;
  }

  .a_g99 {
    width: 97%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
