<!--<table class='table'>
  <thead>
    <tr>
      <th>TechnicianId</th>
      <th>Technicianname</th>
      <th>PhoneNumber</th>
      <th>Email</th>
      <th>Password</th>

    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of serviceengineer ;">
      <td>{{item.TechnicianId}}</td>
      <td>{{item.Technicianname}}</td>
      <td>{{item.PhoneNumber}}</td>
      <td>{{item.Email}}</td>
      <td>{{item.Password}}</td>

    </tr>
  </tbody>
</table>-->

<div class="row ml-n5 mt-n3  ">
  <div class="col">
    <div class="tab-content tab-content">
      <div class="tab-pane fade show active" id="p1">

<p-table #dt [columns]="cols" [value]="serviceengineer" [scrollable]="true" [responsive]="true" [rows]="50" [paginator]="true" scrollHeight="320px">
  <ng-template pTemplate="caption">
    <div class="row">
      <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
        <!--<i (click)="openAddForm()" class="pi pi-plus addIcon" pTooltip="Add Product" tooltipPosition="top" style="font-size:20px;"></i>-->
        <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
           aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>

      </div>
      <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
        {{dt.filteredValue?dt.filteredValue.length:dt.totalRecords}} of {{dt.totalRecords}}
        <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
        <input type="text" class="global_search" pInputText size="50" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col width="60px" />

      <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th>View</th>

      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        <span>{{col.header}}</span>
        <p-sortIcon [field]="col.field"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td class="col-btn">
        <i class="fa fa-eye edit" pTooltip="Viewtechinfo" style="  position:center" (click)="Viewtechinfo(rowData)"></i> <span class="icon-name"></span>
      </td>


      <td *ngFor="let col of columns"  [pTooltip]="rowData[col.field]" tooltipPosition="top" [ngSwitch]="col.field">
        <span class="a_g99" *ngSwitchDefault>{{rowData[col.field]}}</span>
        <span class="a_g99" *ngSwitchCase="'Address'">{{rowData[col.field]}}</span>
        <!--<span *ngSwitchCase="'CreatedDate'">{{rowData[col.field]|date:'dd-MM-YYYY'}}</span>
  <span *ngSwitchCase="'RequestedDate'">{{rowData[col.field]|date:'dd-MM-YYYY'}}</span>
  <span *ngSwitchCase="'StartTime'">{{rowData[col.field]|date:'HH:mm:ss'}}</span>
  <span *ngSwitchCase="'EndTime'">{{rowData[col.field]|date:'HH:mm:ss'}}</span>-->
        <!--<span *ngSwitchCase="'FrontAadharImage'"><img src="{{HomeURL + rowData.FrontAadharImage}}" style="width:100%;cursor:auto;" /></span>-->


      </td>

    </tr>
  </ng-template>
</p-table>
</div>
</div></div></div>
<div class="modal" id="NmyModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header state modal-primary">
        <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">{{technicianid}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" style="color: white;">×</span></button>
      </div>
      <div class="modal-body">
        <div class="row" *ngFor="let item of Techinfo; let i=index" style="width: 100%;" style="text-align: left; padding: 2rem; padding-bottom: 0 !important;">

            <div class="col-lg-6">
              <div style="font-family: poppins;font-size: 17px;font-weight: bold;">FrontAadharImage</div>
              <div style="font-family: poppins;color: #000;font-size: 15px;padding-top: 1rem;letter-spacing: 0.5px;"> <a href="{{HomeURL+item.FrontAadharImage}}" target="_blank"><img src="{{HomeURL+item.FrontAadharImage}}" class="imgsize" /></a></div>

            </div>
            <div class="col-lg-6">
              <div style="font-family: poppins;font-size: 17px;font-weight: bold;">BackAadharImage</div>
              <div style="font-family: poppins;color: #000;font-size: 15px;padding-top: 1rem;letter-spacing: 0.5px;">  <a href="{{HomeURL+item.BackAadharImage}}" target="_blank"><img src="{{HomeURL+item.BackAadharImage}}" class="imgsize" /></a></div>

            </div>
            <div class="col-lg-6 mt-3">
              <div style="font-family: poppins;font-size: 17px;font-weight: bold;">AadharSelfieImage</div>
              <div style="font-family: poppins;color: #000;font-size: 15px;letter-spacing: 0.5px;"> <a href="{{HomeURL+item.AadharSelfieImage}}" target="_blank"><img src="{{HomeURL+item.AadharSelfieImage}}" class="imgsize" /></a></div>

            </div>

          <!--<div class="row" *ngFor="let item of Techinfo; let i=index" style="width:100%;">


            <div class="col-lg-12">

              <div style="font-family: poppins;color: #000;font-size: 15px;padding-top: 1rem;letter-spacing: 0.5px;"> {{item.AadharSelfieImage}}</div>
            </div>
            <div class="col-lg-12">

              <div style="font-family: poppins;color: #000;font-size: 15px;padding-top: 1rem;letter-spacing: 0.5px;"> <img src="{{item.FrontAadharImage}}" /></div>
            </div>
            <div class="col-lg-12">

              <div style="font-family: poppins;color: #000;font-size: 15px;padding-top: 1rem;letter-spacing: 0.5px;"> {{item.BackAadharImage}}</div>
            </div>
          </div>-->


          <br />

          <br />
          <div class="modal-footer" style="float:right;width: 100%;">
            <button style=" background-color: #fc0020; color: #fff; font-family: poppins; font-size: 18px; letter-spacing: 1px; margin-top: 2rem;" id="closemodal" type="button" class="btn btn-default" data-dismiss="modal">
              Close
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
