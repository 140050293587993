import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-productservice',
  templateUrl: './productservice.component.html',
  styleUrls: ['./productservice.component.scss']
})
export class ProductserviceComponent implements OnInit {

  Form: FormGroup;
  loginDet: any;
  cols: any;
  HomeUrl: any;
  Flag: any;
  Status: any;
  ButtonText: any;
  gridview: boolean = true;
  OrganId: any;
  Category: any;
  Brands: any;
  deptList: any;
  BrandID: any;
  CategoryID: any;
  arr1: any;
  CategoryImages1: any;

  imagefile2: any;
  arr: any = [];
  CategoryImage1: any;

  CategoryImage12: any;
  CategoryImage: any;
    ServiceCategoryID: any;

  constructor(public generalService: GeneralserviceService, public fb: FormBuilder, public http: HttpClient) {
    this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
    this.Form = fb.group({
      ServiceCategory: ['', Validators.required],
      //CategoryImage: ['',],
      //MinServiceCharge: ['', Validators.required],
     // SequenceNo: ['', Validators.required],
    });

    this.cols = [

      { field: 'ServiceCategory', header: 'Service Category', width: '390px' },
      ////{ field: 'CategoryImage', header: 'Category Image', width: '180px' },
      ////{ field: 'MinServiceCharge', header: 'Min Service Charge', width: '189px' },
    //  { field: 'SequenceNo', header: 'Sequence No', width: '180px' },
      { field: 'CreatedDate', header: 'Added On', width: '327px;' },

    ]
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    });
  }



  ngOnInit(): void {
    this.GetCategory();

  }

  AddCategory(val, flag) {
    debugger

    var arr = [];
    arr.push({
     ServiceCategoryID: this.ServiceCategoryID,
      ServiceCategory: val.ServiceCategory,
     /* MinServiceCharge: val.MinServiceCharge,*/
     // SequenceNo: val.SequenceNo,
      /*CategoryImage: this.CategoryImage,*/
      Status: this.Status = 'true',

    });
    var UploadFile = new FormData();
    UploadFile.append("Param", JSON.stringify(arr));
    UploadFile.append("Flag", flag);

    var url = "api/Values/Insert_update_delete_Service_Category";

    this.generalService.PostData(url, UploadFile).then(data => {
      debugger
      if (data == "SUCCESS") {
        if (this.Flag == 1) {
          this.generalService.ShowAlert('SUCCESS', 'category Added Successfully ', 'success');
        }
        else {
          this.generalService.ShowAlert('SUCCESS', 'category Updated Successfully', 'success');

        }
        this.Form.reset();
        this.gridview = true;
        this.GetCategory();

      }
      if (data == "Exist") {
        this.generalService.ShowAlert('Exist', 'Data Already Exist ', 'success');
      }
    }, error => ({
      title: 'ERROR',
      text: 'Something went wrong. Please try again later.',
      type: 'error',
      confirmButtonText: 'Ok'

    })
    );
  }
  edit(val) {
    debugger
    this.ButtonText = "Update";
    this.gridview = false;
    this.Flag = 2;
    this.ServiceCategoryID = val.ServiceCategoryID;

    this.Form.controls['ServiceCategory'].setValue(val.ServiceCategory);
 //   this.Form.controls['MinServiceCharge'].setValue(val.MinServiceCharge);
 //   this.Form.controls['SequenceNo'].setValue(val.SequenceNo);
   // this.imagefile2 = this.HomeUrl + val.CategoryImage;
  //  this.CategoryImage = val.CategoryImage;


  }
  /* get/brand with out params*/

  GetCategory() {
    debugger
    var url = "api/Values/Get_admin_ProductServices_Master";
    this.generalService.GetData(url,).then(data => {
      debugger
      this.Category = data;
    }, err => {
      this.generalService.ShowAlert('Error', 'Some thing went Wrong', 'error')
    })

  }

  //**This is used to exist from the form.**//
  cancel() {
    debugger
    this.gridview = true;
    this.GetCategory();
  }

  //**This is used to back from the form.**//
  Back() {
    debugger
    this.gridview = true;
    this.GetCategory();
  }

  //**This is used to open add from**//
  openAddForm() {
    debugger
    this.Status = true;
    this.Form.reset();
    this.gridview = false;
    this.Flag = 1;
    this.ServiceCategoryID = undefined;
    this.ButtonText = "Add";
  //  this.CategoryImage = '';
  //  this.imagefile2 = '';
  }





  onReady(event: any) {
    event.editor.focus();

  }
  onFocus(event: any) {
    console.log("editor is focused");

  }
  onBlur(event: any) {

  }

}
