import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-user-reports',
  templateUrl: './user-reports.component.html',
  styleUrls: ['./user-reports.component.scss']
})
export class UserReportsComponent implements OnInit {

  maxdate: any;
  mindate: any;
  cols: any;
  TotalServicesAmount: any;
  dataResult: any = [];
  SName: any;
  fromDate: any = '';
  toDate: any = '';
  loginDet: any;

  Startdate: string;
  Enddate: string;
  arr: any[];
  site: any;
  assignlist: any = [];
  Result: any;
  SId: any;
  Totalemployees: any;
  Techniciandata: any;
  //  Technicianlist: any;
  UserName: any;
  TotalAmount: number;
  fromdate: any;
  todate: any;
  Techniciandata1: any;
  Technicianlist: any[];
  FirstName: any;
  PhoneNumber: any;
  serviceengineer: any; HomeURL: any;



  constructor(public generalservice: GeneralserviceService, public fb: FormBuilder, public http: HttpClient, public datePipe: DatePipe) {
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeURL = data.Webservice;
    })

    //this.Getserviceengineer();


    this.cols = [

      { field: 'TicketNumber', header: 'Ticket Number', width: '120px' },
      { field: 'TimeSlot', header: 'Time Slot', width: '110px' },
      { field: 'Commission', header: 'Commission', width: '105px', },
      { field: 'CustomerName', header: 'Customer Name', width: '180px', },
      { field: 'TechnicianName', header: 'Technician Name', width: '150px' },
      { field: 'CategoryName', header: 'Category Name', width: '130px' },
      { field: 'AcceptedDate', header: 'Accepted Date', width: '130px' },
      { field: 'BookingDate', header: 'Booking Date', width: '130px' },


    ];
  }

  ngOnInit(): void {

    this.GetUTechnician();

    var date = new Date();
    if (this.Startdate != undefined) {
      this.fromDate = this.Startdate
    }
    else {
      this.fromDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    if (this.Enddate != undefined) {
      this.toDate = this.Enddate
    }
    else {
      this.toDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    }
    this.mindate = this.toDate;
    this.maxdate = this.datePipe.transform(date, 'yyyy-MM-dd');

  }
  Checkdate(fromDate) {

    //this.enddate = startdate;
    this.mindate = fromDate;

  }




  GetUTechnician() {

    this.arr = []
    this.arr.push({
      UserID: this.loginDet.UserID,
      TokenID: this.loginDet.TokenID,
      RoleID: 3
    })

    var UploadFile = new FormData();
    UploadFile.append("Param", JSON.stringify(this.arr));

    var url = "/api/Values/Get_TechnicianGrid";
    this.generalservice.GetData(url).then((data: any) => {

      this.Techniciandata = data;
      this.PhoneNumber = this.Techniciandata.PhoneNumber
      //  this.Techniciandata = this.Techniciandata1.filter(a => a.TstatusID == 2);

    },
      err => {
        this.generalservice.ShowAlert('ERROR', 'Something went wrong,please try agian later', 'error');
      })
  }



  getprojects(fromDate, toDate) {

    this.SId = this.FirstName != undefined ? this.FirstName.TechnicianId : '';

    let formData: FormData = new FormData();
    //formData.append("Param1", JSON.stringify(this.arr));
    formData.append('Param1', fromDate);
    formData.append('Param2', toDate);
    formData.append('Param3', this.SId);

    var url = "api/Values/HQ_UserReports"
    this.generalservice.PostData(url, formData).then((data: any) => {

      if (data == '') {
        this.generalservice.ShowAlert('INFO', 'No Records Found in between dates', 'info');
      }
      if (data != "") {

        //this.dataResult = data
        this.dataResult = []
        this.dataResult = data
        //  this.TotalAmount = 0;
        this.TotalServicesAmount = this.dataResult[0].TotalAmount;
        for (var i = 0; i < this.dataResult.length; i++) {
          this.TotalAmount = this.TotalAmount + this.dataResult[i].TotalAmount;
        }
      }
    });
  }



  ShowAlert(title: any, text: any, type: any) {
    Swal.fire({
      title: title,
      html: text,
      confirmButtonText: 'Ok'
    })
  }


}
