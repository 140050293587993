import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GeneralserviceService } from '../../services/generalservice.service';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public ckeditorContent: any;
  public config: any;
  CommisionAmount: any;
  Amount: any;
  HomeURL: any;
  Users: any;
  arr1: any[];
  form: FormGroup;
  ReferalAmount: any;
  Amount1: any;
  Commision: any;
  Number: any;
  Message: any;
  gridview: number;
  PrivacyPolicy: any;
  Technician_TC: any;
  Customer_TC: any;
  pp: any;
  loginDet: any;
  SMSNumber: any;
  Technician_TC12: any;
  PrivacyPolicy_12: any;
  Customer_12: any;
  ServiceDistance: any;

  constructor(public generalService: GeneralserviceService, public fb: FormBuilder, public http: HttpClient, public router: Router) {
    // this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
    this.http.get('../assets/WebService.json').subscribe((data: any) => {

      this.HomeURL = data.Webservice;
      this.HomeURL = data.Webservice;
      this.ckeditorContent = '<div>Hey we are testing CKEditor</div>';
      this.config = {
        uiColor: '#F0F3F4',
        height: '350',
        extraPlugins: 'divarea'
      };
    });
  }

  ngOnInit(): void {

    this.GetTerms();
    this.GetPrivacyPolicy();
    this.GetCustomer();
    this.GetDistance();
  }


  terms() {

    this.gridview = 1;
  }

  privacy() {

    this.gridview = 2;
  }

  Customer() {

    this.gridview = 3;
  }

  Distance() {
    this.gridview = 4
  }

  updateContent() {
    if (this.gridview === 1) {
      this.TextBoxChanged1();
    } else if (this.gridview === 2) {
      this.TextBoxChanged2();
    } else if (this.gridview === 3) {
      this.TextBoxChanged3();
    } else if (this.gridview === 4) {
      this.TextBoxChanged4();
    }

  }

  TextBoxChanged1() {

    let arr = [];
    arr.push({

    });

    const formData = new FormData();

    this.Technician_TC12 = encodeURIComponent(this.Technician_TC)
    formData.append("Technician_TC", this.Technician_TC12);

    var url = " api/Values/Update_HQ_Technician_tc";
    this.generalService.PostData(url, formData).then((data: any) => {


      this.generalService.ShowAlert('SUCCESS', 'Terms and Conditions Updated successfully', 'success');
    });
  }

  TextBoxChanged2() {

    let arr = [];
    arr.push({

    });

    const formData = new FormData();

    this.PrivacyPolicy_12 = encodeURIComponent(this.PrivacyPolicy);
    formData.append("PrivacyPolicy", this.PrivacyPolicy_12);

    var url = "api/Refit/Update_Refit_PrivacyPolicy";
    this.generalService.PostData(url, formData).then((data: any) => {

      this.generalService.ShowAlert('SUCCESS', 'Privacy Policy Updated successfully', 'success');
    });
  }

  TextBoxChanged3() {

    let arr = [];
    arr.push({

    });

    const formData = new FormData();
    this.Customer_12 = encodeURIComponent(this.Customer_TC);
    formData.append("Customer_TC", this.Customer_12);

    var url = "api/Values/Update_HQ_Customer_tc";
    this.generalService.PostData(url, formData).then((data: any) => {

      this.generalService.ShowAlert('SUCCESS', 'Customer Terms & Conditions Updated successfully', 'success');
    });
  }



  TextBoxChanged4() {

    debugger

    var UploadFile = new FormData();

    UploadFile.append("ServiceBookingDistance", this.ServiceDistance);

    var url = "api/Values/UpdateDistanceRange";
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      this.generalService.ShowAlert('SUCCESS', 'Service Distance Updated successfully', 'success')
    })
  }

  GetTerms() {

    var url = 'api/Values/GetTechnician_TC';
    this.generalService.GetData(url).then((data: any) => {
      this.Technician_TC12 = data[0].Technician_TC;
      this.Technician_TC = decodeURIComponent(this.Technician_TC12);


    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')

    });
  }

  GetPrivacyPolicy() {

    var url = 'api/Values/GetPrivacyPolicy';
    this.generalService.GetData(url).then((data: any) => {
      this.PrivacyPolicy_12 = data[0].PrivacyPolicy;
      this.PrivacyPolicy = decodeURIComponent(this.PrivacyPolicy_12);

    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')

    });
  }

  GetCustomer() {

    var url = 'api/Values/GetCustomerTC';
    this.generalService.GetData(url).then((data: any) => {
      this.Customer_12 = data[0].Customer_TC;
      this.Customer_TC = decodeURIComponent(this.Customer_12);

    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')

    });
  }


  GetDistance() {

    debugger
    var url = 'api/Values/GetDistanceRange';
    this.generalService.GetData(url).then((data: any) => {
      debugger
      this.ServiceDistance = data[0].ServiceBookingDistance;
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')

    });
  }
  // texteditor methods
  onChange(event: any) {
    setTimeout(() => {
      this.ckeditorContent = event;
    });
  }
  onReady(event: any) {
    event.editor.focus();

  }
  onFocus(event: any) {
    console.log("editor is focused");

  }
  onBlur(event: any) {

  }

}
