//import { Injectable } from '@angular/core';

//@Injectable({
//  providedIn: 'root'
//})

//export class AuthService {
//  loginDetails: any;

//  isUserAuthenticated() {
//    return !!localStorage.getItem('LoginDetails');
//  }
   
//  isAuthenticated() {
//    debugger
//    this.loginDetails = JSON.parse(localStorage.getItem('LoginDetails'));
//    if (this.loginDetails != null) {
//      return true;
//    }
//    else {
//      return false;
//    }
//  }
//}
// auth.guard.ts

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    loginDetails: any;
  constructor(private router: Router) { }
    isUserAuthenticated() {
    return !!localStorage.getItem('LoginDetails');
  }
  canActivate(): boolean {
    // Your logic to check if the user is logged in
    this.loginDetails = JSON.parse(localStorage.getItem('LoginDetails'));
    if (!this.loginDetails) {
      this.router.navigate(['/login']); // Redirect to login if not logged in
      return false;
    }
    return true;
  }
}
