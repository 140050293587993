import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../services/generalservice.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  customers: any;
  cols: any;
  
  constructor(public generalService: GeneralserviceService) {
    this.Getcustomers();
    this.cols = [

    //  { field: 'CustomerId', header: '#', width: '50px' },
      { field: 'FirstName', header: 'Customer Name', width: '150px' },
      //{ field: 'LastName', header: 'LastName', width: '130px' },
      { field: 'Mobile', header: 'Mobile', width: '80px' },
      { field: 'Email', header: 'Email', width: '180px' },
      { field: 'CreatedDate', header: 'Created Date & Time', width: '150px' },
      //{ field: 'CreatedDate', header: 'Time', width: '150px' },
      //{ field: 'ServiceInformation', header: 'ServiceInformation', width: '190px' },


    ];
  }
 
  ngOnInit() {
  }

  /*Get customers */
  Getcustomers() {
    debugger
    var url = "/api/Values/HQ_Customer_Grid";


    this.generalService.GetData(url).then((data: any) => {
      debugger
      this.customers = data;
      

    }, err => {
      this.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')

    });
  }



  /*end code*/



  ShowAlert(title: any, text: any, type: any) {
    Swal.fire({
      title: title,
      html: text,
      //icon: type,
      confirmButtonText: 'Ok'
    })
  }
}
