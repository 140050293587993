import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../services/generalservice.service';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
declare var $


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  CategoryName: any;
  Id: any;

  Flag: any;
  Offerprice: number;
  Description: number;

  Price: any;
  ProductName: string;
  imagefile: any;
  ProductImagePdfPath: any;
  ServiceInformation: any;
  msg: string;
  image: string;
  CatId: any;
  ButtonText: string;
  Products: any;
  cols: { field: string; header: string; width: string; }[];
  HomeURL: any;
  gridview: boolean = false;
  Subcategory: any;
  ProductImage: any;
  Product: any;
  TypeServiceDetail: any;
  ServiceCategory: any;
  TypeServiceCategory: any;
  Image: any;
  ImageData: any;
  TypeServiceDetailsId: any;
  ImageArray: any;
  Category: any;
  SubCatId: any;
  Subcategorydata: any;

  constructor(public generalService: GeneralserviceService, public http: HttpClient, public router: Router) {
    if (localStorage.getItem("LoginDetails") == null) {
      this.router.navigate(['/login']);
    }
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      debugger
      this.HomeURL = data.Webservice;
    })

    this.cols = [

      { field: 'TypeServiceDetail', header: 'Product', width: '25%' },
      { field: 'ServiceCategory', header: 'Category', width: '25%' },
      { field: 'TypeServiceCategory', header: 'Subcategory', width: '25%' },
      // { field: 'Image', header: 'Image', width: '100px' },
      { field: 'Status', header: 'Status', width: '25%' },
      { field: 'Description', header: 'Description', width: '25%' },
      { field: 'Price', header: 'Price', width: '25%' },
      //  { field: 'TypeServiceDetailsId', header: 'TypeServiceDetailsId', width: '150px' },
      //  { field: 'ServiceInfoId', header: 'ServiceInfoId', width: '150px' },
    ]
  }

  ngOnInit(): void {
    this.GetCategory();
    this.GetProducts();
    this.GetSubCategory();
  }

  GetSubCategory() {
    var url = '/api/Values/Get_SubCategoriesFilter';
    this.generalService.GetData(url).then((data: any) => {
      debugger
      this.Subcategory = data;
      this.Subcategorydata = data;
      /* console.log(this.SubCategory);*/
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      //this.spinner = false;
    });
  }

  GetCategory() {
    var url = 'api/Values/Get_CategoryDropdown';
    this.generalService.GetData(url).then((data: any) => {
      debugger
      this.Category = data;
      //console.log(this.Category);
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      //this.spinner = false;
    });
  }

  FilterSubCategories(val) {
    debugger
    this.Subcategory = [];
    this.Subcategory = this.Subcategorydata.filter(a => a.ServiceCategoryID == val)
  }
  GetProducts() {
    var url = 'api/Values/Get_ProductsGrid';
    this.generalService.GetData(url).then((data: any) => {
      debugger
      this.Products = data;
      //console.log(this.Category)
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      //this.spinner = false;
    });
  }


  AddProduct(ServiceCategory, TypeServiceCategory, Description, TypeServiceDetail, Price) {
    debugger
    if (ServiceCategory == "" || TypeServiceCategory == "" || Description == "" || TypeServiceDetail == "" || Price == "") {
      this.generalService.ShowAlert("WARNING", "Please Fill The All The Required", "warning");
    }
    else {
      let arr = [];
      arr.push({
        ServiceCategory: this.ServiceCategory
        , TypeServiceCategory: this.TypeServiceCategory
        , Image: this.image
        , TypeServiceCategoryId: this.SubCatId
        , Description: this.Description
        , TypeServiceDetail: this.TypeServiceDetail

      })
      let arr1 = [];
      arr1.push({
        ServiceInformation: this.TypeServiceDetail
        , Price: this.Price
      })


      var UploadFile = new FormData();
      UploadFile.append("ServiceDetails1", JSON.stringify(arr));
      UploadFile.append("ServiceInfo1", JSON.stringify(arr1));
      UploadFile.append("Flag", this.Flag);

      var url = 'api/Values/Insertproducts';
      this.generalService.PostData(url, UploadFile).then((data: any) => {

        debugger
        if (data == 'SUCCESS') {
          if (this.Flag == 1) {
            this.generalService.ShowAlert("SUCCESS", "Product added successfully", "success");
          }
          else {
            this.generalService.ShowAlert("SUCCESS", "Product updated successfully", "success");

          }
          this.GetProducts();
          this.Back();
        }
        else {
          this.generalService.ShowAlert("WARNING", "Something went wrong please try agin", "warning");
        }
      })

    }

  }

  //delete product
  confirmDelete(rowData) {
    debugger
    Swal.fire({
      title: 'Alert',
      text: 'Are you sure to delete this Product ?',
      showCancelButton: true,
      icon: 'warning',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((data1) => {
      if (data1.value == true) {
        //Swal.fire({
        //  title: 'Alert',
        //  text: 'Are you sure to delete the Image ?',
        //  type: 'warning',
        //  showCancelButton: true,
        //  confirmButtonText: 'Yes, delete it!',
        //  cancelButtonText: 'No, keep it'
        //}).then((data1) => {
        //  if (data1.value == true) {
        let arr = [];
        arr.push({
          TypeServiceDetailsId: rowData.TypeServiceDetailsId
          , ServiceCategory: this.ServiceCategory
          , TypeServiceCategory: this.TypeServiceCategory
          , Image: this.image
          , TypeServiceCategoryId: this.SubCatId
          , Description: this.Description
          , TypeServiceDetail: this.TypeServiceDetail

        })
        let arr1 = [];
        arr1.push({
          ServiceInfoId: rowData.ServiceInfoId,
          ServiceInformation: this.TypeServiceDetail
          , Price: this.Price
        })


        var UploadFile = new FormData();
        UploadFile.append("ServiceDetails1", JSON.stringify(arr));
        UploadFile.append("ServiceInfo1", JSON.stringify(arr1));
        UploadFile.append("Flag", '3');
        var url = 'api/Values/Insertproducts';
        this.generalService.PostData(url, UploadFile).then((data: any) => {
          debugger
          if (data == 'SUCCESS') {
            if (this.Flag == 3) {
              this.generalService.ShowAlert("SUCCESS", "Product Deleted Successfully", "success");
            }
            this.GetProducts();
          }
        })
      }
    })
  }

  ShowAlert(title: any, text: any, type: any) {
    Swal.fire({
      title: title,
      html: text,
      confirmButtonText: 'Ok'
    })
  }
  openAddForm() {
    this.Clear();
    this.gridview = true;
    this.ButtonText = "Add";
    this.Flag = 1;
    this.image = '';
    this.msg = "Product Added Successfully";
    //this.ServiceCategory = '';
    this.TypeServiceDetail = '';
    //this.TypeServiceCategory = '';


  }
  Edit(rowData) {
    debugger
    this.Id = rowData.Id;
    this.gridview = true;
    this.ButtonText = "Update";
    this.Flag = 2;
    this.image = rowData.Image;
    this.ServiceCategory = rowData.ServiceCategory,
      this.TypeServiceCategory = rowData.TypeServiceCategory,
      this.Description = rowData.Description,
      this.TypeServiceDetail = rowData.TypeServiceDetail,

      this.Price = rowData.Price,
      this.CatId = rowData.ServiceCategoryId,
      this.SubCatId = rowData.TypeServiceCategoryId

  }
  Clear() {
    this.image = "",
      this.CatId = 0,
      this.SubCatId = 0,
      this.TypeServiceDetail = null,
      this.Description = null,
      this.Price = null

  }
  Back() {
    this.gridview = false;
  }


  ViewImage(rowData) {
    debugger
    //this.spinner.show();

    TypeServiceDetailsId: this.TypeServiceDetailsId,
      this.TypeServiceDetailsId = rowData.TypeServiceDetailsId

    var FormDat = new FormData();
    FormDat.append("TypeServiceDetailsId", this.TypeServiceDetailsId);
    var url = "/api/Values/Get_Productimage";
    this.generalService.PostData(url, FormDat).then((data: any) => {
      debugger
      this.Image = data;
      //this.image = this.HomeURL + data;

      debugger
      if (data.length != 0) {
        debugger
        this.Image = data;
        //this.spinner.hide();
        $('#NmyModal').modal('show');
      }

      else {
        //$('#NmyModal').modal('hide');

        //this.spinner.hide();
        this.generalService.ShowAlert('INFO', 'No Data Found', 'info');
      }
    }, err => {
      //this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }


  detectFiles(input) {
    debugger
    const reader = new FileReader();
    if (input.files.length) {
      debugger
      const file = input.files[0];
      this.ProductImagePdfPath = input.files[0];
      reader.onload = () => {
        debugger
        this.imagefile = reader.result;
        this.image = this.imagefile;
      }
      reader.readAsDataURL(file);
      var selectedFile = file;
      var idxDot = selectedFile.name.lastIndexOf(".") + 1;
      var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        debugger
        var UploadFile = new FormData();
        UploadFile.append("PostedFile", file);
        var url = 'api/Values/uploadImage';
        this.generalService.PostData(url, UploadFile).then((data: any) => {
          if (data != null) {
            debugger
            this.imagefile = this.HomeURL + data;
            this.image = data;
          }
        });
      }
      else {
        alert("Only jpg/jpeg, png files are allowed!");
      }

    }
  }

  fileChange(event) {
    debugger
    //let fileList = event.target.files;
    let fileList = event.target.files;

    if (fileList.length > 0) {
      const frmData = new FormData();
      for (var i = 0; i < fileList.length; i++) {
        frmData.append("PostedFile", fileList[i]);
      }
      var url = 'api/Values/uploadImage';
      this.generalService.PostData(url, frmData).then((data: any) => {
        if (data != null) {
          debugger
          this.ImageData = data;
          for (var i = 0; i < this.ImageData.length; i++) {
            debugger
            this.ImageArray.push({ ProductImage: this.ImageData[i] })
            //this.Imagelist += "<Images><Image>" + this.ImageData[i] + "</Image></Images>"
          }
        }
      });
    }



  }

}

