import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../services/generalservice.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ToastrService, GlobalConfig } from 'ngx-toastr';
declare var $

@Component({
  selector: 'app-sub-categories',
  templateUrl: './sub-categories.component.html',
  styleUrls: ['./sub-categories.component.scss']
})
export class SubCategoriesComponent implements OnInit {

  Tabelname: string;
  Status: any;
  CategoryId: any;
  id: any;
  Id: any;
  CategoryType: any;
  CategoryName: string;
  image: any;
  imagefile: any;
  ProductImagePdfPath: any;
  msg: string;
  ImageArray: any[];
  Flag: any;
  ButtonText: string;
  Category: any;
  HomeURL: any;
  Image: any;
  cols: { field: string; header: string; width: string; }[];
  gridview: boolean = false;
  TypeServicesCategoryId: any;
  ImageData: any;
  SubCategory: any;
  Description: any;
  TypeServiceCategoryCode: any;
  TypeServiceCategory: any;
  SubCategoryImage: any;
  CatId: any;
  SubCategoryStatus: any;

  constructor(public generalservice: GeneralserviceService, public http: HttpClient, public router: Router) {
    if (localStorage.getItem("LoginDetails") == null) {
      this.router.navigate(['/login']);
    }
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      debugger
      this.HomeURL = data.Webservice;
    });

    this.cols = [


      { field: 'ServiceCategory', header: 'Service Category', width: '25%' },
      { field: 'TypeServiceCategory', header: 'TypeService Category', width: '25%' },
      { field: 'TypeServiceCategoryCode', header: 'TypeService CategoryCode', width: '25%' },

      //{ field: 'ServiceCategoryID', header: 'SID', width: '100px' },
      //{ field: 'Status', header: 'Status', width: '100px' },
      //{ field: 'Image', header: 'Image', width: '100px' },

    ];
  }
  ngOnInit() {
    this.GetSubCategory();
    this.GetCategory();
  }
  GetCategory() {
    var url = 'api/Values/Get_CategoryDropdown';
    this.generalservice.GetData(url).then((data: any) => {
      debugger
      this.Category = data;
      //console.log(this.Category);
    }, err => {
      this.generalservice.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      //this.spinner = false;
    });
  }
  GetSubCategory() {
    var url = '/api/Values/Get_SubCategories';
    this.generalservice.GetData(url).then((data: any) => {
      debugger
      this.SubCategory = data;/* console.log(this.SubCategory);*/
    }, err => {
      this.generalservice.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      //this.spinner = false;
    });
  }

  ViewImage(rowData) {
    debugger
    //this.spinner.show();

    TypeServicesCategoryId: this.TypeServicesCategoryId,
      this.TypeServicesCategoryId = rowData.TypeServicesCategoryId

    var FormDat = new FormData();
    FormDat.append("TypeServicesCategoryId", this.TypeServicesCategoryId);
    var url = "api/Values/Get_SubCategoryimage";

    this.generalservice.PostData(url, FormDat).then((data: any) => {
      debugger
      this.Image = data;
      //this.image = this.HomeURL + data;

      debugger
      if (data.length != 0) {
        debugger
        this.Image = data;
        //this.spinner.hide();
        $('#NmyModal').modal('show');
      }

      else {
        //$('#NmyModal').modal('hide');

        //this.spinner.hide();
        this.generalservice.ShowAlert('INFO', 'No Data Found', 'info');
      }
    }, err => {
      //this.spinner.hide();
      this.generalservice.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }


  detectFiles(input) {
    debugger
    const reader = new FileReader();
    if (input.files.length) {
      debugger
      const file = input.files[0];
      this.ProductImagePdfPath = input.files[0];
      reader.onload = () => {
        debugger
        this.imagefile = reader.result;
        this.image = this.imagefile;
      }
      reader.readAsDataURL(file);
      var selectedFile = file;
      var idxDot = selectedFile.name.lastIndexOf(".") + 1;
      var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        debugger
        var UploadFile = new FormData();
        UploadFile.append("PostedFile", file);
        var url = 'api/Values/uploadImage';
        this.generalservice.PostData(url, UploadFile).then((data: any) => {
          if (data != null) {
            debugger
            this.imagefile = this.HomeURL + data;
            this.image = data;
          }
        });
      }
      else {
        alert("Only jpg/jpeg, png files are allowed!");
      }

    }
  }

  fileChange(event) {
    debugger
    //let fileList = event.target.files;
    let fileList = event.target.files;

    if (fileList.length > 0) {
      const frmData = new FormData();
      for (var i = 0; i < fileList.length; i++) {
        frmData.append("PostedFile", fileList[i]);
      }
      var url = 'api/Values/uploadImage';
      this.generalservice.PostData(url, frmData).then((data: any) => {
        if (data != null) {
          debugger
          this.ImageData = data;
          for (var i = 0; i < this.ImageData.length; i++) {
            debugger
            this.ImageArray.push({ ProductImage: this.ImageData[i] })
            //this.Imagelist += "<Images><Image>" + this.ImageData[i] + "</Image></Images>"
          }
        }
      });
    }



  }

  AddCategory(TypeServiceCategory, TypeServiceCategoryCode, TypeServicesCategoryId) {
    debugger

    if (TypeServiceCategory == "" || TypeServiceCategoryCode == "" || TypeServicesCategoryId == "") {
      this.generalservice.ShowAlert("WARNING", "Please Fill The All The Required", "warning");
    }
    else {
      let arr = [];
      arr.push({
        TypeServiceCategory: this.TypeServiceCategory
        , Image: this.image
        , TypeServiceCategoryCode: this.TypeServiceCategoryCode
        , TypeServicesCategoryId: this.TypeServicesCategoryId
        , ServiceCategoryId: this.CatId
        , Status: true
      })
      var UploadFile = new FormData();
      UploadFile.append("SubCategoryDetails", JSON.stringify(arr));
      UploadFile.append("Flag", this.Flag);

      var url = 'api/Values/Add_update_delete_Subcategory';
      this.generalservice.PostData(url, UploadFile).then((data: any) => {

        debugger
        if (data == 'SUCCESS') {
          if (this.Flag == 1 || this.Status == true) {
            this.generalservice.ShowAlert("SUCCESS", "SubCategory added successfully", "success");
          }
          else {
            this.generalservice.ShowAlert("SUCCESS", "SubCategory updated successfully", "success");

          }

          this.GetSubCategory();
          this.Back();
        }
        else {
          this.generalservice.ShowAlert("WARNING", "Something went wrong please try agin", "warning");
        }
      })

    }

  }
  ShowAlert(title: any, text: any, type: any) {
    Swal.fire({
      title: title,
      html: text,
      confirmButtonText: 'Ok'
    })
  }


  /*update active and dactivate status on categories*/
  UpdateStatus(ev, rowData) {
    debugger
    // var url = "api/Values/Add_update_delete_category?ServiceCategory=" + ServiceCategory + "&Image=" + this.image + "&ServiceCategoryCode=" + ServiceCategoryCode;
    this.TypeServicesCategoryId = rowData.TypeServicesCategoryId;
    this.SubCategoryStatus = ev.checked;

    let arr = [];
    arr.push({
      TypeServicesCategoryId: this.TypeServicesCategoryId
      , Status: this.SubCategoryStatus
    })
    var UploadFile = new FormData();
    UploadFile.append("SubCategoryDetails", JSON.stringify(arr));
    UploadFile.append("Flag", '3');

    var url = 'api/Values/Add_update_delete_Subcategory';
    this.generalservice.PostData(url, UploadFile).then((data: any) => {

      debugger;
      if (data == 'SUCCESS') {
        this.generalservice.ShowAlert("SUCCESS", "SubCategory Status Updated Successfully", "success");
      }
    });
  }
  /*end code*/

  Edit(rowData) {
    debugger
    this.gridview = true;
    this.ButtonText = "Update";
    this.Flag = 2;
    this.TypeServicesCategoryId = rowData.TypeServicesCategoryId;
    this.TypeServiceCategory = rowData.TypeServiceCategory;
    this.TypeServiceCategoryCode = rowData.TypeServiceCategoryCode;
    this.image = rowData.Image;
    this.imagefile = this.HomeURL + rowData.Image;
    this.CatId = rowData.ServiceCategoryID;
    this.Status = true;
  }
  Clear() {
    this.image = "",
      this.CatId = 0,

      this.TypeServiceCategory = null,
      this.Description = null,
      this.TypeServiceCategoryCode = null

  }
  openAddForm() {
    this.Clear();
    this.gridview = true;
    this.ButtonText = "Add";
    this.Flag = 1;
    this.image = '';
    this.msg = "SubCategory Added Successfully";
    this.TypeServiceCategory = "";
    this.TypeServiceCategoryCode = "";
  }
  Back() {
    this.gridview = false;
  }
  //Reset() {
  //  this.ServiceCategoryId = null;
  //  this.ServiceCategory = '';
  //  this.Description = '';
  //  this.ServiceCategoryCode = '';
  //  this.Image = null;
  //  this.Status = null;

  //}
}

