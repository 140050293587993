import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router'; 
import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { TechnicianComponent } from './pages/technician/technician.component';
import { BookingsComponent } from './pages/bookings/bookings.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { UserReportsComponent } from './pages/user-reports/user-reports.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ProductserviceComponent } from './pages/productservice/productservice.component';
import { RateCardComponent } from './pages/rate-card/rate-card.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { SubCategoriesComponent } from './pages/sub-categories/sub-categories.component';
import { ProductsComponent } from './pages/products/products.component';
import { AuthGuard } from '././services/auth.service'
export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },

  { path: '', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },

  {
    path: '', canActivate: [AuthGuard],
    component: PagesComponent,
    children:[
      { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' }  },          
    { path: 'blank', component: BlankComponent, data: { breadcrumb: 'Blank page' } },
      { path: 'Customers', component: CustomersComponent, data: { breadcrumb: 'Customers' } },
      { path: 'Technician', component: TechnicianComponent, data: { breadcrumb: 'Technician' } },
      { path: 'Bookings', component: BookingsComponent, data: { breadcrumb: 'Bookings' } },
      { path: 'notification', component: NotificationComponent, data: { breadcrumb: 'Notifications' } },
      { path: 'UserReports', component: UserReportsComponent, data: { breadcrumb: 'UserReports' } },
      { path: 'Settings', component: SettingsComponent, data: { breadcrumb: 'Settings' } },
      { path: 'productservice', component: ProductserviceComponent, data: { breadcrumb: 'productservice' } },
      { path: 'RateCard', component: RateCardComponent, data: { breadcrumb: ' RateCard' } },
      { path: 'categories', component: CategoriesComponent, data: { breadcrumb: ' categories' } },
      { path: 'subcategories', component: SubCategoriesComponent, data: { breadcrumb: ' subcategories' } },
      { path: 'products', component: ProductsComponent, data: { breadcrumb: ' products' } },
    ]
  },
  { path: 'Master', loadChildren: () => import('./pages/master/master.module').then(m => m.masterModule), data: { breadcrumb: 'Master' } },

  { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
  { path: '**', component: NotFoundComponent }
]; 

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      relativeLinkResolution: 'legacy',
      // useHash: true
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
