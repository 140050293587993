<!--<div style="text-align: right;margin-bottom: 0; ">
  <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
  <input type="text" class="global_search" pInputText size="50" placeholder="Global Filter" (input)=" onInput($event)" style="width:auto">
</div>-->
<!--<div class="p-scrollpanel-bar">

  <div class="card-body pt-0 widget-body">
    <div class="container-fluid">

      <table class='table' style="width:800px;overflow-x:scroll;position:absolute;">
        <thead>

          <tr>
            <th>TicketNumber</th>
            <th>CustomerName</th>
            <th>Mobile</th>
            <th>Technicianname</th>

            <th>ServiceInformation</th>
            <th>Price</th>
            <th>TotalPrice</th>
            <th>StartTime</th>
            <th>EndTime</th>
            <th>CreatedDate</th>
            <th>RequestedDate</th>
            <th>AssignTo</th>
            <th>LastAssignTo</th>
            <th>Coins</th>
            <th>Amount</th>
            <th>TicketStatus</th>

          </tr>

        </thead>
        <tbody class="list">
          <tr *ngFor="let item of serviceticket ;">
            <td>{{item.TicketNumber}}</td>
            <td>{{item.CustomerName}}</td>
            <td>{{item.Mobile}}</td>
            <td>{{item.Technicianname}}</td>

            <td>{{item.ServiceInformation}}</td>
            <td>{{item.Price}}</td>
            <td>{{item.TotalPrice}}</td>
            <td>{{item.StartTime|date:'HH:mm:ss'}}</td>
            <td>{{item.EndTime|date:'HH:mm:ss'}}</td>
            <td>{{item.CreatedDate|date:'dd-MM-YYYY'}}</td>
            <td>{{item.RequestedDate|date:'dd-MM-YYYY'}}</td>
            <td>{{item.AssignTo}}</td>
            <td>{{item.LastAssignTo}}</td>
            <td>{{item.Coins}}</td>
            <td>{{item.Amount}}</td>
            <td>{{item.TicketStatus}}</td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</div>-->







<div class="row ml-n5 mt-n3">
  <div class="col">
    <div class="tab-content tab-content">
      <div class="tab-pane fade show active" id="p1">
        <p-table #dt [columns]="cols" [value]="serviceticket" [scrollable]="true" [responsive]="true" [rows]="16" [paginator]="true" scrollHeight="350px">
          <ng-template pTemplate="caption">
            <div class="row">
              <div class="col-lg-4" style="text-align: left;margin-bottom: 0;">

                <!--<i (click)="openAddForm()" class="pi pi-plus addIcon" pTooltip="Add Product" tooltipPosition="top" style="font-size:20px;"></i>-->
                <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
                   aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>
              </div>

              <div class="col-lg-7" style="text-align: right;margin-bottom: 0;">
                {{dt.filteredValue?dt.filteredValue.length:dt.totalRecords}} of {{dt.totalRecords}}
                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                <input type="text" class="global_search" pInputText size="50" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
              </div>
              <div class="col-lg-1 " style="text-align:justify">
                <div class="dropdown mt-n3">
                  <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
                    <i class="fa fa-cog fa-2x" aria-hidden="true"></i>
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="javascript:void(0);" (click)="getTicketsByStatus(0)">All ({{ticketsListALL.length}})</a>
                    <a class="dropdown-item" href="javascript:void(0);" (click)="getTicketsByStatus(1)">Inprogress ({{ticketsListOpen.length}})    </a>
                    <a class="dropdown-item" href="javascript:void(0);" (click)="getTicketsByStatus(2)">Assigned   ({{ticketsListInPro.length}})   </a>
                    <a class="dropdown-item" href="javascript:void(0);" (click)="getTicketsByStatus(3)">Completed  ({{ticketsListClosed.length}})   </a>

                    <a class="dropdown-item" href="javascript:void(0);" (click)="getTicketsByStatus(4)">Cancel  ({{ticketsCancel.length}})   </a>
                  </div>
                </div>
              </div>
            </div>

          </ng-template>

          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <col width="60px" />
              <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
            </colgroup>
          </ng-template>


          <ng-template pTemplate="header" let-columns>
            <tr>
              <th>View</th>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                <span>{{col.header}}</span>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td class="col-btn">
        <i class="fa fa-eye edit" pTooltip="Viewserviceinfo" style="" (click)="Viewserviceinfo(rowData)"></i> <span class="icon-name"></span>
      </td>


      <td *ngFor="let col of columns" [ngSwitch]="col.field">
        <span class="a_g99" *ngSwitchDefault>{{rowData[col.field]}}</span>

        <div class="pr-2" style="text-align:right">
          <span *ngSwitchCase="'TotalPrice'">{{rowData[col.field]| currency:'INR' }}</span>
          <span *ngSwitchCase="'FinalPrice'">{{rowData[col.field]| currency:'INR' }}</span>
        </div>
        <span *ngSwitchCase="'CreatedDate'">{{rowData[col.field]|date:'dd-MMM-yyyy,hh:mm:a'}}</span>
        <span *ngSwitchCase="'RequestedDate'">{{rowData[col.field]|date:'dd-MM-YYYY'}}</span>
        <span *ngSwitchCase="'StartTime'">{{rowData[col.field]|date:'HH:mm:ss'}}</span>
        <span *ngSwitchCase="'EndTime'">{{rowData[col.field]|date:'HH:mm:ss'}}</span>
        <span  *ngSwitchCase="'CancellationReason'" >{{rowData[col.field]}}</span>

      </td>
    </tr>
  </ng-template>



          <!--<ng-template pTemplate="body" let-rowData let-columns="columns">
            <ng-container *ngIf="rowData.TicketStatus !== 4; ">
              <tr>
                <td class="col-btn">
                  <i class="fa fa-eye edit" pTooltip="Viewserviceinfo" style="" (click)="Viewserviceinfo(rowData)"></i> <span class="icon-name"></span>
                </td>
                <td *ngFor="let col of columns" [ngSwitch]="col.field">
                  <span class="a_g99" *ngSwitchDefault>{{rowData[col.field]}}</span>
                  <div class="pr-2" style="text-align:right" *ngSwitchCase="'TotalPrice'">
                    <span>{{rowData[col.field] | currency:'INR' }}</span>
                  </div>
                  <div class="pr-2" style="text-align:right" *ngSwitchCase="'FinalPrice'">
                    <span>{{rowData[col.field] | currency:'INR' }}</span>
                  </div>
                  <span *ngSwitchCase="'CreatedDate'">{{rowData[col.field] | date:'dd-MMM-yyyy,hh:mm:a'}}</span>
                  <span *ngSwitchCase="'RequestedDate'">{{rowData[col.field] | date:'dd-MM-YYYY'}}</span>
                  <span *ngSwitchCase="'StartTime'">{{rowData[col.field] | date:'HH:mm:ss'}}</span>
                  <span *ngSwitchCase="'EndTime'">{{rowData[col.field] | date:'HH:mm:ss'}}</span>
                  <span *ngSwitchCase="'CancellationReason'">{{rowData[col.field]}}</span>
                </td>
              </tr>
            </ng-container>
            <ng-template *ngIf="rowData.TicketStatus === 4">
              <tr>
                <td class="col-btn">
                  <i class="fa fa-eye edit" pTooltip="Viewserviceinfo" style="" (click)="Viewserviceinfo(rowData)"></i> <span class="icon-name"></span>
                </td>
                <td *ngFor="let col of columns" [ngSwitch]="col.field">
                  <span class="a_g99" *ngSwitchDefault>{{rowData[col.field]}}</span>
                  <div class="pr-2" style="text-align:right" *ngSwitchCase="'TotalPrice'">
                    <span>{{rowData[col.field] | currency:'INR' }}</span>
                  </div>
                  <div class="pr-2" style="text-align:right" *ngSwitchCase="'FinalPrice'">
                    <span>{{rowData[col.field] | currency:'INR' }}</span>
                  </div>
                  <span *ngSwitchCase="'CreatedDate'">{{rowData[col.field] | date:'dd-MMM-yyyy,hh:mm:a'}}</span>
                  <span *ngSwitchCase="'RequestedDate'">{{rowData[col.field] | date:'dd-MM-YYYY'}}</span>
                  <span *ngSwitchCase="'StartTime'">{{rowData[col.field] | date:'HH:mm:ss'}}</span>
                  <span *ngSwitchCase="'EndTime'">{{rowData[col.field] | date:'HH:mm:ss'}}</span>
                  <span *ngSwitchCase="'CancellationReason'">{{rowData[col.field]}}</span>
                </td>
              </tr>
            </ng-template>
          </ng-template>-->

        </p-table>
      </div>
    </div>
  </div>
</div>
<!-- #region Popup for view customer sale stock-->
<div class="modal" id="NmyModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header state modal-primary">
        <h4 style="text-align:left;" class="modal-title" id="modal-primary-label"><span>Ticket Number: </span>{{ServiceTickedId}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"style="color:white;">×</span></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6"><div class="headding">Service Information</div>  </div>
          <div class="col-lg-2"style="margin-left: -15px;"><div class="headding">Price</div> </div>
          <div class="col-lg-1"style="margin-left: -11px;"><div class="headding">Qty</div></div>
          <div class="col-lg-3"style="margin-left: 7px;"><div class="headding">T.Price</div></div>
        </div>

        <div class="row" *ngFor="let item of serviceinfo; let i=index" style="width:100%;">
          <div class="col-lg-6">
            <div class="data">  {{item.ServiceInformation}}</div>
          </div>
          <div class="col-lg-2">
            <div style="font-family: poppins; color: #000; font-size: 13px; margin-left: 0rem; padding-top: 0.8rem; letter-spacing: 0.5px;"> {{item.Price |  currency:'INR'}}</div>
          </div>
          <div class="col-lg-1">
            <div class="data">{{item.Quantity}}</div>

          </div>
          <div class="col-lg-3">
            <div class="data">{{item.Price*item.Quantity | currency:"INR"}}</div>
          </div>
        </div>

        <div class="row" *ngFor="let item of spareDetails; let i=index" style="width:100%;">
          <div class="col-lg-6">
            <div class="data">  {{item.Title}}</div>
          </div>
          <div class="col-lg-2">
            <div style="font-family: poppins; color: #000; font-size: 13px; margin-left: 0rem; padding-top: 0.8rem; letter-spacing: 0.5px;"> {{item.Price |  currency:'INR'}}</div>
          </div>
          <div class="col-lg-1">
            <div class="data">{{item.Quantity}}</div>

          </div>
          <div class="col-lg-3">
            <div class="data">{{item.Price*item.Quantity | currency:"INR"}}</div>
          </div>
        </div>

        <!--<div class="row" *ngFor="let item of serviceinfo; let i=index" style="text-align:left">
    <div class="col-lg-10">
      {{item.ServiceInformation}}
    </div>

    <div class="col-lg-2">

    </div>



  </div>-->
        <br />

        <br />
        <div class="row">
          <div class="col-lg-8"></div>
          <div class="col-lg-4" style="margin-left:241px;">Grand Total&nbsp;=</div>
          <div class="data" style="margin-left: -4rem;margin-top: -12px;">
            {{TotalPrice |currency:"INR"}}
          </div>
          </div>
        <div class="modal-footer" style="float:right;width: 100%;">
          <button id="closemodal" type="button" class="btn btn-default button_close" data-dismiss="modal">
            Close
          </button>
        </div>

      </div>
    </div>
  </div>
</div>
<!-- #endregion -->
