<nav class="navbar box-shadow app-header" [class.fixed-top]="settings.theme.navbarIsFixed" [class.logo-visible]="showInfoContent">
    <div class="d-flex flex-column w-100">
        <div id="info-content" class="panel-collapse collapse">
            <!--<a class="close" data-toggle="collapse" href="#info-content" (click)="showInfoContent = !showInfoContent" [class.show]="showInfoContent">
                <span aria-hidden="true">&times;</span>
            </a>-->
            <!--<div class="row align-items-center">
                <div class="col-md-5">
                    <div class="d-flex justify-content-center align-items-center pt-md-0 pt-3 info">
                        <img src="assets/img/users/user.jpg" class="rounded-circle user-img"> 
                        <div class="d-flex flex-column pl-3">
                            <h5 class="mb-0">Emilio Verdines <i class="fa fa-check-circle fs-13 text-success ml-2"></i></h5>
                            <span>Project menecer</span>
                            <div class="pt-3">
                                <a routerLink="/profile" class="btn btn-success mr-1">Profile</a> 
                                <a routerLink="/profile/user-info" class="btn btn-gray">Edit</a> 
                            </div>                            
                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="row py-4">                        
                        <div class="col-lg-7 col-sm-8 col-12 left-border">
                            <div class="info w-280p mx-auto text-center">
                                <form>
                                    <div class="form-group">
                                        <input type="text" class="form-control form-control-sm" placeholder="Recipients">
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control form-control-sm" placeholder="Subject">
                                    </div>
                                    <div class="form-group">
                                        <textarea class="form-control form-control-sm" placeholder="Message" rows="3"></textarea>
                                    </div>
                                    <button type="submit" class="btn btn-sm btn-gray"><i class="fa fa-paper-plane mr-2"></i>Send</button>
                                </form>
                            </div>                           
                        </div>
                        <div class="col-lg-5 col-sm-4 left-border d-none d-sm-block">
                            <div class="info w-100p mx-auto">
                                <div class="custom-control custom-checkbox mb-1">
                                    <input type="checkbox" class="custom-control-input" id="checkboxNotifications" checked>
                                    <label class="custom-control-label" for="checkboxNotifications">Notifications</label>
                                </div>
                                <div class="custom-control custom-checkbox mb-1">
                                    <input type="checkbox" class="custom-control-input" id="checkboxTasks" checked>
                                    <label class="custom-control-label" for="checkboxTasks">Tasks</label>
                                </div>
                                <div class="custom-control custom-checkbox mb-1">
                                    <input type="checkbox" class="custom-control-input" id="checkboxEvents">
                                    <label class="custom-control-label" for="checkboxEvents">Events</label>
                                </div>
                                <div class="custom-control custom-checkbox mb-1">
                                    <input type="checkbox" class="custom-control-input" id="checkboxDownloads" checked>
                                    <label class="custom-control-label" for="checkboxDownloads">Downloads</label>
                                </div>
                                <div class="custom-control custom-checkbox mb-1">
                                    <input type="checkbox" class="custom-control-input" id="checkboxMessages" checked>
                                    <label class="custom-control-label" for="checkboxMessages">Messages</label>
                                </div>
                                <div class="custom-control custom-checkbox mb-1">
                                    <input type="checkbox" class="custom-control-input" id="checkboxUpdates">
                                    <label class="custom-control-label" for="checkboxUpdates">Updates</label>
                                </div>
                                <div class="custom-control custom-checkbox mb-1">
                                    <input type="checkbox" class="custom-control-input" id="checkboxSettings" checked>
                                    <label class="custom-control-label" for="checkboxSettings">Settings</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>-->
        </div>
        <div class="d-flex justify-content-start align-items-center top-navbar">                  
            
            <a class="logo" routerLink="/" (click)="closeSubMenus()">
                <span class="start">HQ</span><span class="ng">AC</span>
            </a>

            <span class="navbar-text menu-icon transition ml-3 mr-3" [ngClass]="{'open': settings.theme.showMenu}"
                (click)="settings.theme.showMenu = !settings.theme.showMenu">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </span>

            <!--<div class="ml-2 mr-2 d-none d-sm-block">
                 <a id="info-content-icon" data-toggle="collapse" href="#info-content" (click)="showInfoContent = !showInfoContent" class="d-flex" [class.show]="showInfoContent">
                    <div [@showInfo]="showInfoContent" class="triangle"></div>
                </a>
            </div>-->

            <!--<form class="app-search d-none d-md-inline-block ml-3">
                <i class="fa fa-search"></i>
                <input type="text" placeholder="Type to search..." class="form-control">
            </form>-->

           <!-- <app-favorites class="d-none d-lg-inline-block"></app-favorites>-->

            <div class="top-rigth-icons ml-auto">
                <span class="d-none d-sm-inline-block">
                    <!--<app-flags-menu></app-flags-menu>-->
                </span> 
                <span class="d-none d-sm-inline-block">
                    <!--<app-fullscreen></app-fullscreen>-->
                </span> 
                <span class="d-none d-sm-inline-block">
                    <!--<app-applications></app-applications>-->                    
                </span> 
                <span class="d-none d-sm-inline-block">
                    <!--<app-messages></app-messages>-->
                </span>               
                <!--<span class="d-none d-sm-inline-block pl-2 pr-2" (click)="settings.theme.showSideChat = !settings.theme.showSideChat">
                    <i class="fa fa-commenting-o" aria-hidden="true"></i>
                </span>--> 
                <span class="d-none d-sm-inline-block">
                    <!--<app-user-menu></app-user-menu>-->
                </span> 
                <span class="pl-2 pr-2">
                    <a routerLink="/login">
                        <i class="fa fa-sign-out" aria-hidden="true"></i>
                    </a>                   
                </span> 
            </div> 

        </div>

        <div class="horizontal-menu-outer" *ngIf="settings.theme.menu == 'horizontal'">                   
            <app-horizontal-menu *ngIf="showHorizontalMenu"  [menuItems]="menuItems"></app-horizontal-menu>        
            <app-vertical-menu *ngIf="!showHorizontalMenu"  [menuItems]="menuItems"></app-vertical-menu>
        </div>

    </div>
</nav>
