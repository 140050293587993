
<!--<table class='table'>
  <thead>
    <tr>
      <th>ID</th>
      <th>FirstName</th>
      <th>LastName</th>
      <th>Mobile</th>
      <th>Email</th>
      
      <th>CreatedDate</th>
   
      <th>ServiceInfoId</th>
      <th>ServiceInformation</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of customers ;">
      <td>{{item.CustomerId}}</td>
      <td>{{item.FirstName}}</td>
      <td>{{item.LastName}}</td>
      <td>{{item.Mobile}}</td>
      <td>{{item.Email}}</td>
      
      <td>{{item.CreatedDate}}</td>
      <td>{{item.ServiceInfoId}}</td>
      <td>{{item.ServiceInformation}}</td>
    </tr>
  </tbody>
</table>-->

<div class="row   ml-n5 mt-n3">
  <div class="col">
    <div class="tab-content tab-content">
      <div class="tab-pane fade show active" id="p1">
        <p-table #dt [columns]="cols" [value]="customers" [scrollable]="true" [responsive]="true" [rows]="16" [paginator]="true" scrollHeight="320px">
          <ng-template pTemplate="caption">
            <div class="row">
              <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
                <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
                   aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>

              </div>
              <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
                {{dt.filteredValue?dt.filteredValue.length:dt.totalRecords}} of {{dt.totalRecords}}
                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                <input type="text" class="global_search" pInputText size="50" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
            </colgroup>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>

              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                <span>{{col.header}}</span>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>


              <td *ngFor="let col of columns" [ngSwitch]="col.field">
                <span class="a_g99" *ngSwitchDefault>{{rowData[col.field]}}</span>

                <span *ngSwitchCase="'CreatedDate'">{{rowData[col.field]|date:'dd-MM-YYYY'}}, {{rowData[col.field]|date:'HH:mm:ss'}}</span>
                <!--<span *ngSwitchCase="'RequestedDate'">{{rowData[col.field]|date:'dd-MM-YYYY'}}</span>
                <span *ngSwitchCase="'StartTime'">{{rowData[col.field]|date:'HH:mm:ss'}}</span>
                <span *ngSwitchCase="'EndTime'">{{rowData[col.field]|date:'HH:mm:ss'}}</span>-->

              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
