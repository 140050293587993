<fieldset class="scheduler-border" [formGroup]="Form" *ngIf="!gridview">
  <legend class="scheduler-border" style="text-align:center">{{ButtonText}} Service Category </legend>

  <div class="row">
    <div class="col-lg-2 ">
      <button type="button" (click)="Back()" class="btn btn-danger btn-border-radius waves-effect mb-2"> <i class="material-icons"></i> <span class="icon-name">Back</span></button>

    </div>

  </div>
  <!----------------------- insert and update form fields in Sub Category Master------------------>
  <div class="row">

    <div class="col-lg-4">
      <div class="form-group">
        <label><b>Service Category<span style="color:red">*</span></b></label>
        <input class="form-control" formControlName="ServiceCategory" type="text" placeholder="ServiceCategory">
        <small *ngIf="Form.controls.ServiceCategory.touched && Form.controls.ServiceCategory.errors?.required" class="p-error">ServiceCategory is required.</small>

      </div>
    </div>


    <!--<div class="col-lg-4">
      <div class="form-group">
        <label><b>Min Service Charge<span style="color:red">*</span></b></label>
        <input class="form-control" formControlName="MinServiceCharge" type="text" placeholder="MinServiceCharge">
        <small *ngIf="Form.controls.MinServiceCharge.touched && Form.controls.MinServiceCharge.errors?.required" class="p-error">Charge is required.</small>

      </div>
    </div>-->

    <!--<div class="col-lg-4">
      <div class="form-group">
        <label><b>Sequence No<span style="color:red">*</span></b></label>
        <input class="form-control" formControlName="SequenceNo" type="text" placeholder="SequenceNo">
        <small *ngIf="Form.controls.SequenceNo.touched && Form.controls.SequenceNo.errors?.required" class="p-error">Sequence No is required.</small>

      </div>
    </div>-->

    <!--<div class="col-lg-4">
      <div class="col-sm-10">
        <label><b> Category Image<span style="color:red"></span></b></label>
        <div class="images-container">
          <div class="m-image-wrapper">
            <img [attr.src]="imagefile2" *ngIf="imagefile2" style="cursor:pointer;">
          </div>
        </div>  <br />
        <input formControlName="CategoryImage" input type="file" (change)="detectFiles2(input2)" accept="image/*" #input2 class="m-img-upload-btn" />
        <small style="color:red" class="text-danger" *ngIf="Form.controls.CategoryImage.touched && Form.controls.CategoryImage.errors?.required">Image  is required</small>

      </div>
    </div>-->

  </div>
  <br />
  <div class="form-group" style="text-align:right">
    <button type="button" style="margin-top:0px;" (click)="Back()" class="btn btn-danger m-t-15 waves-effect">Cancel</button>&nbsp;
    <button *ngIf="Flag==1" type="submit" (click)="AddCategory(Form.value,1)" [disabled]="Form.invalid" class="btn btn-primary m-t-15 waves-effect">Submit</button>
    <button *ngIf="Flag==2" type="submit" (click)="AddCategory(Form.value,2)" [disabled]="Form.invalid" class="btn btn-primary m-t-15 waves-effect">Update</button>


  </div>
</fieldset>




<div class="row ml-n5 mt-n3">
  <div class="col">
    <div *ngIf="gridview" class=" borderrr">
      <div id="p1">
        <p-table #dt [columns]="cols" [value]="Category" [scrollable]="true" [responsive]="true" [rows]="20" [paginator]="true" scrollHeight="450px">
          <!--<p-table #dt [columns]="cols" [value]="dataResult" [responsive]="true" [rows]="18" [paginator]="true">-->
          <ng-template pTemplate="caption">
            <div class="row">
              <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
                <i (click)=" openAddForm()" class="fa fa-plus addIcon" pTooltip="ADD" tooltipPosition="top" style="font-size:20px;"></i>
                <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
                   aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>


              </div>
              <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
                {{dt.filteredValue?dt.filteredValue.length:dt.totalRecords}} of {{dt.totalRecords}}
                <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                <input type="text" class="global_search search" pInputText size="50" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
              </div>

            </div>
          </ng-template>
          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>


              <!--<col style="width:68px">-->
              <col style="width:87px" />
              <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">

            </colgroup>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>

              <th class="border-right" style="width:100px">Action</th>

              <th class="border-right" *ngFor="let col of columns" [pSortableColumn]="col.field">
                <span>{{col.header}}</span>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>

            </tr>

          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td class="border-right">

                <i (click)="edit(rowData)" pTooltip="Edit" style="font-size:15px;cursor:pointer;" class="pl-1 fa fa-pencil-square-o" aria-hidden="true"></i>

                &nbsp; <!--<i (click)="Delete(rowData)" class="fa fa-trash" style="font-size:15px;float:initial;color: red;cursor:pointer;"></i>-->
                <!--<div class="divvv">
                  <p-inputSwitch style="margin-top: 7px;" [(ngModel)]="rowData.Status" class="status_Btn" (onChange)="UpdateStatus($event,rowData)" style="margin-left:-15px"></p-inputSwitch>
                </div>-->
              </td>



              <td class="border-right " *ngFor="let col of columns" [pTooltip]="rowData[col.field]" tooltipPosition="top" [ngSwitch]="col.field">
                <span class="a_g99  pl-1" *ngSwitchDefault>{{rowData[col.field]}}</span>

                <span class="pl-1" *ngSwitchCase="'CreatedDate'">{{rowData[col.field] | date:'dd-MMM-yyyy'}}</span>
                <span class="pl-1" *ngSwitchCase="'CategoryImage'">
                  <a href="{{HomeUrl+rowData[col.field]}}" target="_blank">
                    <img src="{{HomeUrl+rowData[col.field]}}" style=" height: 20px;" />
                  </a>
                </span>

                <div style="text-align:right;">
                  <!--<span class="pl-3 pr-2" *ngSwitchCase="'MinServiceCharge'">{{rowData[col.field] | currency:'INR' }}</span>-->
                  <span class="pl-1 pr-2" *ngSwitchCase="'SequenceNo'">{{rowData[col.field]}}</span>

                </div>


              </td>


            </tr>
          </ng-template>

        </p-table>

      </div>
    </div>
  </div>
</div>



<style>
  .modal-dialog1 {
    max-width: 31% !important;
    margin: 1.75rem auto;
  }

  .p-inputswitch-slider {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: 20px;
  }

  .app.green .btn-success {
    color: #fff;
    background-color: #4BAE4F;
    border-color: #4BAE4F;
    width: 39% !important;
    letter-spacing: 1px !important;
    margin-top: 1% !important;
  }

  .modal-dialog {
    max-width: 60% !important;
    margin: 1.75rem auto !important;
  }

  .card {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.11), 0 3px 6px rgba(0, 0, 0, 0.02);
  }

  .custom.tabs-primary {
    background-color: rgba(62, 80, 180, 0) !important;
    border: 1px solid #ffffff !important;
    COLOR: #000 !important;
  }

  .nav-item .nav-link {
    color: rgba(0, 0, 0, 0.8) !important;
  }

    .nav-item .nav-link.active {
      background-color: #ffffff !important;
      border-bottom: 3px solid #9ed9f7 !important;
    }

    .nav-item .nav-link:hover {
      background-color: #9ed9f7 !important;
      border-bottom: 3px solid #9ed9f7 !important;
    }

  .HideDescription {
    width: 100%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .strg_c {
    font-family: poppins;
    font-size: 12px;
    color: #000;
    letter-spacing: 0.5px;
    line-height: 1.8;
    text-align: center;
    padding-top: 0;
  }


  .strg {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #000;
    letter-spacing: 0.5px;
    text-align: center;
    padding-top: 0.5rem
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  /*td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }*/

  #plugin {
    height: 30% !important;
    position: absolute;
    width: 100%;
  }

  .a_g99 {
    width: 97%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 0%;
  }

  .status_Btn {
    font-size: 10px;
    margin-left: 10px;
    margin-top: -36px;
  }

  td.border-right {
    border-right: 1px solid black;
  }

  tr:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
</style>
