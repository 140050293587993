<!--<div class="card">
    <div class="card-body pt-0 widget-body">
        <div class="container-fluid"></div>
        <div class="row mb-4">
            <div class="col">-->

<fieldset class="scheduler-border" [formGroup]="Form" *ngIf="!gridview">
  <legend class="scheduler-border" style="text-align:center">{{ButtonText}} Rate Card </legend>


  <div class="row">
    <div class="col-lg-2 ">
      <button type="button" (click)="Back()" class="btn btn-danger btn-border-radius waves-effect" style="margin-top:20px"> <i class="material-icons"></i> <span class="icon-name">Back</span></button>
      <br /><br />
    </div>

  </div>
  <!----------------------- insert and update form fields------------------>
  <div class="row">
    <div class="col-lg-4">
      <div class="form-group">
        <label><b>Tittle Name<span style="color:red;text-align:right;">*</span></b></label>
        <input class="form-control" formControlName="Title" type="text" placeholder="Title">
        <small style="color:red" class="text-danger" *ngIf="Form.controls.Title.touched && Form.controls.Title.errors?.required">Title is required</small>
      </div>
    </div>

    <div class="col-lg-4">

      <div class="form-group">
        <label><b>Price<span style="color:red;text-align:right;">*</span></b></label>
        <input class="form-control" formControlName="Price" type="text" placeholder="Price">
        <small style="color:red" class="text-danger" *ngIf="Form.controls.Price.touched && Form.controls.Price.errors?.required">Price is required</small>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="form-group">
        <label><b>Description<span style="color:red;text-align:right;">*</span></b></label>
        <input class="form-control" formControlName="Description" type="text" placeholder="Description">
        <small style="color:red" class="text-danger" *ngIf="Form.controls.Description.touched && Form.controls.Description.errors?.required">Description is required</small>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="form-group">
        <label><b>Commission Percentage<span style="color:red;text-align:right;">*</span></b></label>
        <input class="form-control" formControlName="CommissionPercentage" type="text" placeholder="CommissionPercentage">
        <small style="color:red" class="text-danger" *ngIf="Form.controls.CommissionPercentage.touched && Form.controls.Title.errors?.required">CommissionPercentage is required</small>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="form-group">
        <label><b>Commission Amount<span style="color:red;text-align:right;">*</span></b></label>
        <input class="form-control" formControlName="CommissionAmount" type="text" placeholder="CommissionPercentage">
        <small style="color:red" class="text-danger" *ngIf="Form.controls.CommissionAmount.touched && Form.controls.Title.errors?.required">CommissionPercentage is required</small>
      </div>
    </div>


    <div class="col-lg-4">

      <label class="head_txt"> <b class="task">Service Category<span style="color:red;">*</span></b></label>



      <div class="form-group mt-n1 ">

        <p-autoComplete [suggestions]="subtasklist" (completeMethod)="filterGroupsSingle5($event)" dataKey='Id' field="ServiceCategory" formControlName="ServiceCategory"  [dropdown]="true" [size]="50"
                        placeholder="Search with Service Category " [minLength]="1" forceSelection="true">
          <ng-template let-autofillCustomers pTemplate="item">
            <div style="padding-left:10px">
              <div class="row">
                <div style="height:15px">
                  {{autofillCustomers.ServiceCategory}}
                </div>
              </div>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>
      <!--<small style="color:red" class="text-danger" *ngIf="Form.controls.CategoryName.touched && Form.controls.CategoryName.errors?.required">CategoryName is required</small>-->

    </div>
  </div>
  <br />
  <div class="form-group" style="text-align:right">

    <button type="button" style="margin-top:0px;" (click)="Back()" class="btn btn-danger m-t-15 waves-effect">Cancel</button>&nbsp;
    <button *ngIf="Flag==1" type="submit" (click)="AddBrand(Form.value,1)" [disabled]="Form.invalid" class="btn btn-primary m-t-15 waves-effect">Submit</button>
    <button *ngIf="Flag==2" type="submit" (click)="AddBrand(Form.value,2)" [disabled]="Form.invalid" class="btn btn-primary m-t-15 waves-effect">Update</button>

  </div>
</fieldset>
<!--This is used to display all products in a grid format.-->
<div *ngIf="gridview" class="borderrr ml-n4 mt-n2">
  <div id="p1">
    <p-table #dt [columns]="cols" [value]="dataResult" [responsive]="true" [scrollable]="true" [paginator]="true"
             [rows]="15" scrollHeight="350px">
      <ng-template pTemplate="caption">
        <div class="row">
          <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
            <i (click)=" openAddForm()" class="fa fa-plus addIcon" pTooltip="ADD" tooltipPosition="top"
               style="font-size:20px;"></i>
            <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
               aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4;
                cursor:pointer;margin-left:8px;"></i>

          </div>
          <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
            {{dt.filteredValue?dt.filteredValue.length:dt.totalRecords}} of {{dt.totalRecords}}
            <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
            <input type="text" class="global_search search" pInputText size="50"
                   placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')"
                   style="width:auto">
          </div>

        </div>
      </ng-template>
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>



          <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>

          <th class="border-right" style="width:80px">Action</th>
          <th class="border-right" style="width:180px;"*ngFor="let col of columns" [pSortableColumn]="col.field">
            <span class="pl-1">{{col.header}}</span>
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">

        <tr style="line-height: 1 !important;">
          <!-- edit button functionality-->
          <td class="border-right" style="width:80px">
            <i class="fa fa-pencil-square edit pl-n2" pTooltip="Edit" (click)="edit(rowData)" style=""></i> <span class="icon-name"></span>
            &nbsp;
            <!--<div class=" ml-5 mb-n1 mt-n4">
              <p-inputSwitch [(ngModel)]="rowData.Status" (onChange)="UpdateStatus($event,rowData)"></p-inputSwitch>
            </div>-->
          </td>
          <!-- delete  functionality-->
          <!--<td class="col-btn">
            <i class="fa fa-trash" (click)="delete(rowData)" style="color:red;  font-size: 17px;"></i> <span class="icon-name"></span>
          </td>-->

          <td class="border-right " style="width:180px;" *ngFor="let col of columns" [pTooltip]="rowData[col.field]" tooltipPosition="top" [ngSwitch]="col.field">
            <span class="a_g99 pl-n5 space1" *ngSwitchDefault>{{rowData[col.field]}}</span>

            <span class="space1 pl-n5" style="width:180px;" *ngSwitchCase="'CreatedDate'">{{rowData[col.field]|date:'dd-MM-YYYY,hh:mm:a'}}</span>


          </td>
        </tr>

      </ng-template>
    </p-table>
  </div>
</div>
<!--</div>
        </div>
    </div>
</div>-->

<style>
  .modal-dialog1 {
    max-width: 31% !important;
    margin: 1.75rem auto;
  }


  .app.green .btn-success {
    color: #fff;
    background-color: #4BAE4F;
    border-color: #4BAE4F;
    width: 39% !important;
    letter-spacing: 1px !important;
    margin-top: 1% !important;
  }

  .modal-dialog {
    max-width: 60% !important;
    margin: 1.75rem auto !important;
  }

  .card {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.11), 0 3px 6px rgba(0, 0, 0, 0.02);
  }

  .custom.tabs-primary {
    background-color: rgba(62, 80, 180, 0) !important;
    border: 1px solid #ffffff !important;
    COLOR: #000 !important;
  }

  .nav-item .nav-link {
    color: rgba(0, 0, 0, 0.8) !important;
  }

    .nav-item .nav-link.active {
      background-color: #ffffff !important;
      border-bottom: 3px solid #9ed9f7 !important;
    }

    .nav-item .nav-link:hover {
      background-color: #9ed9f7 !important;
      border-bottom: 3px solid #9ed9f7 !important;
    }

  .HideDescription {
    width: 100%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .strg_c {
    font-family: poppins;
    font-size: 12px;
    color: #000;
    letter-spacing: 0.5px;
    line-height: 1.8;
    text-align: center;
    padding-top: 0;
  }


  .strg {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #000;
    letter-spacing: 0.5px;
    text-align: center;
    padding-top: 0.5rem
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  /*td, th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }*/

  #plugin {
    height: 30% !important;
    position: absolute;
    width: 100%;
  }

  .a_g99 {
    width: 90%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 4%;
  }

  td.border-right {
    border-right: 1px solid black;
  }

  tr:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }

  .status_Btn {
    font-size: 10px;
    margin-left: 50px;
    margin-top: -36px;
    padding-top: -100px;
  }

  .action {
    width: 100px;
  }

  .divvv {
    margin-left: 26px;
    margin-top: -17px;
  }
</style>
