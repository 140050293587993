import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
declare var $

@Component({
  selector: 'app-rate-card',
  templateUrl: './rate-card.component.html',
  styleUrls: ['./rate-card.component.scss']
})
export class RateCardComponent implements OnInit {

  TaskTypeID: any;

  Rolelist: {};
  Role: any;
  arr1: any;
  flag: any;
  loginDet: any;
  cols: any;
  dataResult: any = [];
  dataResult1: any;
  gridview: boolean = true;

  arr: any = [];
  Form: FormGroup;
  Flag: any;

  CategoryTypeID: any;
  Status: any;
  ButtonText: any;
  subtasklist: any;
  subtasks: any;
  CategoryName: any;
  BrandID: any;
  Id: any;
  ServiceCategory: any;
  Title: any;
  Price: any;
  CommissionPercentage: any;
  CommissionAmount: any;
  Description: any;

  constructor(public generalService: GeneralserviceService, public fb: FormBuilder, public http: HttpClient) {
    this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
    this.Form = fb.group({
      Title: ['', Validators.required],
      Price: ['', Validators.required],
      Description: ['', Validators.required],
      ServiceCategory: ['', ],
      CommissionPercentage: ['', Validators.required ],
      CommissionAmount: ['', Validators.required],




    });

    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      //this.HomeUrl = data.Webservice;
    });
  }

  ngOnInit(): void {
    this.cols = [


      { field: 'Title', header: 'Title', width: '100px;' },
      { field: 'Price', header: 'Price', width: '100px;' },
      { field: 'Description', header: 'Description', width: '130px;' },
      { field: 'ServiceCategory', header: 'Service Category', width: '173px;' },
      { field: 'CommissionPercentage', header: 'Commission Percentage', width: '205px;' },
      { field: 'CommissionAmount', header: 'Commission Amount', width: '200px;' },
      { field: 'CreatedDate', header: 'Added On', width: '120px;' },
    ]
    this.getTasktype();
    this.getSubtask();


  }

  getSubtask() {
    debugger
    var url = "api/Values/Get_admin_ProductServices_Master";
    this.generalService.GetData(url).then(data => {
      this.subtasks = data;
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
    })
  }

  // The dropdown and search filter categories are linked by this code.
  filterGroupsSingle5(event) {
    debugger
    let filtered: any = [];
    let query = event.query;
    {
      this.subtasklist = [];
      for (let i = 0; i < this.subtasks.length; i++) {
        let type = this.subtasks[i];
        if (type.ServiceCategory.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(type);
          this.ButtonText = 'Add';
        }
      }
      this.subtasklist = filtered;
    }
  }


  //**With the use of flags, you can add and alter customer..**//
  AddBrand(value, flag) {

    this.arr = []
    this.arr.push({
      Id: this.Id,
   //   UserId: this.loginDet.Id,
  //    TokenID: this.loginDet.TokenID,
      Title: value.Title,
      Price: value.Price,
      Description:value.Description,
      CommissionPercentage: value.CommissionPercentage,
      CommissionAmount: value.CommissionAmount,
      ServiceCategoryID: value.ServiceCategory.ServiceCategoryID,
      Status: this.Status = 'true',
    });
    var UploadFile = new FormData();
    UploadFile.append("Param", JSON.stringify(this.arr));
    UploadFile.append("Flag", flag);
    var url = "api/Values/Insert_Update_Delete_RateCard";
    this.generalService.PostData(url, UploadFile).then(data => {

      if (data != null) {

        if (data == "SUCCESS") {
          if (this.Flag == 1) {
            this.generalService.ShowAlert('SUCCESS', 'Brand added successfully.', 'success');
          }
          else {
            this.generalService.ShowAlert('SUCCESS', 'Brand updated successfully.', 'success');
          }

          this.getTasktype();
          this.Form.reset();
          this.gridview = true;
        }
        else {
          this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error');
        }
      }
    });
  }



  getTasktype() {

    this.arr = []
    this.arr.push({

      UserID: this.loginDet.Id,
      TokenID: this.loginDet.TokenID,

    });

  

    var url = "api/Values/GetRatecard";
    this.generalService.GetData(url).then(data => {

      this.dataResult = data;

    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
    })
  }
  // The dropdown and search filter categories are linked by this code.

  //**This is used to edit the from**//
  edit(val) {

    this.ButtonText = "Update";
    this.gridview = false;
    this.Flag = 2;
    this.Id = val.Id;
    this.Form.controls["Title"].setValue(val.Title);
    this.Form.controls["Price"].setValue(val.Price);
    this.Form.controls["Description"].setValue(val.Description);
    this.Form.controls["CommissionPercentage"].setValue(val.CommissionPercentage);
    this.Form.controls["CommissionAmount"].setValue(val.CommissionAmount);
    let cont = this.subtasks.filter(a => a.ServiceCategoryID == val.ServiceCategoryID);
    this.Form.controls["ServiceCategory"].setValue(cont[0]);

  }
  //**This is used to exist from the form.**//
  cancel() {

    this.gridview = true;
  }

  //**This is used to back from the form.**//
  Back() {

    this.gridview = true;
  }

  //**This is used to open add from**//
  openAddForm() {

    this.Status = true;
    this.Form.reset();
    this.gridview = false;
    this.Flag = 1;
    this.Id = undefined;
    this.ButtonText = "Add";

  }

  //UpdateStatus(e, rowData) {

  //  this.arr = [];
  //  this.arr.push({ BrandID: rowData.BrandID, Status: e.checked })
  //  var UploadFile = new FormData();
  //  UploadFile.append("Param", JSON.stringify(this.arr));
  //  UploadFile.append("Flag", "4");
  //  var url = "api/Refit/Insert_update_delete_Brands"
  //  this.generalService.PostData(url, UploadFile).then((data: any) => {

  //    if (data == 'SUCCESS') {
  //      this.generalService.ShowAlert('SUCCESS', ' Updated Successfully', 'success')
  //      this.getTasktype();
  //    }
  //    else {
  //      this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
  //    }
  //  }, err => {
  //    this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
  //  });
  //}

}
