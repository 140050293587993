<div class="card-body pt-0 widget-body">
  <div class="form-group mb-0 pt-1 pb-1">
    <div class="input-group" style="margin-left: 16px;">
      <div class="row">
        <div class="col-11">
          <ul class="nav nav-tabs custom top" role="tablist">
            <li class="nav-item">
              <a class="nav-link" style="cursor:pointer;" href="#terms" role="tab" data-toggle="tab" (click)="terms()">Technician Terms & Conditions</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#privacy" role="tab" data-toggle="tab" (click)="privacy()">Privacy Policy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#Customer" role="tab" data-toggle="tab" (click)="Customer()">Customer Terms & Conditions</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#Distance" role="tab" data-toggle="tab" (click)="Distance()">Service Booking Distance</a>
            </li>
          </ul>
        </div>
        <div class="col-1">
          <button type="button" class="btn btn-primary m-t-15 waves-effect" (click)="updateContent()">Update</button>
        </div>
      </div>
    </div>

    <div class="container">
      <div *ngIf="gridview == 4">
        <label>
          Service Distance (KM)
        </label>
        <br />
        <div>
          <textarea style="height:40px; width: 10%;" id="w3review" name="w3review" rows="4" cols="100" (change)="TextBoxChanged4" [(ngModel)]="ServiceDistance">
                    </textarea>
          &nbsp;

        </div>
      </div>
    </div>

    <div class="container">
      <div *ngIf="gridview == 1">
        <label for="Technician_TC" style="font-size:20px; margin-left: -2px"></label>
        <ckeditor [(ngModel)]="Technician_TC"
                  [config]="config"
                  (change)="onChange($event)"
                  (ready)="onReady($event)"
                  (focus)="onFocus($event)"
                  (blur)="onBlur($event)">
        </ckeditor>
      </div>
    </div>

    <div class="container">
      <div *ngIf="gridview == 2">
        <label for="PrivacyPolicy" style="font-size:20px; margin-left: -2px"></label>
        <ckeditor [(ngModel)]="PrivacyPolicy"
                  [config]="config"
                  (change)="onChange($event)"
                  (ready)="onReady($event)"
                  (focus)="onFocus($event)"
                  (blur)="onBlur($event)">
        </ckeditor>
      </div>
    </div>

    <div class="container">
      <div *ngIf="gridview == 3">
        <label for="Customer_TC" style="font-size:20px; margin-left: -2px"></label>
        <ckeditor [(ngModel)]="Customer_TC"
                  [config]="config"
                  (change)="onChange($event)"
                  (ready)="onReady($event)"
                  (focus)="onFocus($event)"
                  (blur)="onBlur($event)">
        </ckeditor>
      </div>
    </div>

    <br />
  </div>
</div>

